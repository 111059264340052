import React, { useEffect, useState } from "react";
import img3 from "../assets/images/Asset17.png";
import img2 from "../assets/images/Asset16.png";
import img1 from "../assets/images/Asset15.png";
import InfiniteCarousel from "react-leaf-carousel";
import plus from "../assets/images/pluswhite.svg";
import download from "../assets/images/download.svg";
import axios from "axios";
import moment from "moment";

export default function PublicationItem(props) {
  let { multiSelectData } = props;

  const [data, setdata] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [showperPage, setshowperPage] = useState(3);
  const [disablebtn, setdisablebtn] = useState(false);
  const [err, setErr] = useState("");

  const [fromDate, setFromDate] = useState('')
  const [tillDate, setTillDate] = useState('')
  const [type, setType] = useState('')
  const [order, setOrder] = useState('')


  const indexofLast = currentPage * showperPage;
  const indexofFirst = indexofLast - showperPage;
  const currentlyPages = data.slice(indexofFirst, indexofLast);
  const asset_path = "upload/";


  
  const getAllPublications = (fromDate,tillDate,type,order) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}public/publications?fromDate=${fromDate?fromDate:''}&tillDate=${tillDate ? tillDate : ''}&type=${type?type:''}&dateOrder=${order?order:'ASC'}`
      )
      .then(function (response) {
        setdata(response?.data?.Publications)
        console.log(
          response?.data?.Publications,
          "response?.data?.Publications"
        );
      })
      .catch(function (error) {
        if(error?.response?.status == 404 ){
          setErr("No Data Found !")
          setdata([])
        }
        else{setErr("")}
      });
  };



  
    let check = () => {
      if (multiSelectData?.type) {
        setType(multiSelectData.type)
        getAllPublications(multiSelectData.fromDate,multiSelectData.tillDate,multiSelectData.type,multiSelectData.order);
        
      } else if (multiSelectData?.fromDate) {
        setFromDate(multiSelectData.fromDate)
        setTillDate(multiSelectData.tillDate)
        getAllPublications(multiSelectData.fromDate,multiSelectData.tillDate,multiSelectData.type,multiSelectData.order);
      }
      if (multiSelectData?.order) {
        setOrder(multiSelectData.order)
        getAllPublications(multiSelectData.fromDate,multiSelectData.tillDate,multiSelectData.type,multiSelectData.order);
        
      }


       else {
      }
      
    };
  
  
    const nextpage = () => {
      if (data?.length >= showperPage) {
        setshowperPage(3 + showperPage);
      } else {
        setdisablebtn(true);
      }
    };

  useEffect(() => {
    getAllPublications(fromDate,tillDate,type);
  }, []);

  useEffect(() => {
    check();
  }, [multiSelectData]);


  return (
    <section className="banner-publicationItems">
      <div className="border-publication container">
        <div className="row">
          {currentlyPages.length === 0 && err ? <h4 style={{textAlign:"center",color:"red"}}>{err}</h4> :
          currentlyPages.length !== 0 ? currentlyPages.map((row) => (
            <div className="col-lg-4 col-md-4">
              <div className="img-div-events">
                <img
                  className="events-slider-div img-fluid"
                  alt=""
                  src={asset_path + row?.Image}
                />
                <div className="events-slider-text">
                  <div className="events-slider-innner-text-div">
                    <p
                      className="Montserrat-M greenColor"
                      style={{ fontSize: "9pt" }}
                    >
                      {row?.Type}
                    </p>
                    <ul className="liststyletype">
                      <li className="Montserrat-B">
                        <b>{row?.Title}</b>
                      </li>
                    </ul>
                    <div className="download-publication">
                      <p
                        className="Montserrat-SB grayColor"
                        style={{ fontSize: "6pt" }}
                      >
                        {moment(row?.Date).format("MM-DD-YYYY")}
                        01-01-2022
                      </p>
                      <img
                        className="publication-download-svg"
                        alt=""
                        src={download}
                      />
                    </div>
                  </div>
                  <div className="events-plus-div">
                    <a
                      href={asset_path + row?.Pdf}
                      target="_blank"
                      type="button"
                      className="btngreen btn btn-light"
                    >
                      <img className="events-plus-svg" alt="" src={download} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )) :  null}
          <div className="foundation-minus globlbtnsdiv">
            {disablebtn ? (
              ""
            ) : (
              <button
                type="button"
                onClick={() => nextpage()}
                className="Poppins-R custmbtn publicationbtn btn btn-light"
              >
                Load More
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
