import React from "react";
import Van from "../assets/images/van.png";
import { Accordion } from "react-bootstrap";
import service from "../assets/images/Assett8.svg";

export default function ServiceMobile() {
  return (
    <section className="serviceMobile-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6" data-aos="flip-left" data-aos-delay="400">
            <img className="SMimg" src={Van} alt="image" loading="lazy" />
            <div className="servicemobile-img-testing"></div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="container">
              <div className="row">
                <div className="mt-5">
                  <div className="bannerthree-heading-contant" data-aos="fade-up" data-aos-delay="400">
                    <div className="contant-heading">
                      <h5 className="Montserrat-M grayColor">
                        SERVICES - <b>MOBILE DISPENSARY</b>
                      </h5>
                    </div>
                    <div className="contant3">
                      <h2 className="Montserrat-B greenColor">
                      
                            A reliable and convenient solution to providing healthcare

                      </h2>
                    </div>
                    <div className="greenline-bannertwo"></div>
                    <div className="contant-ptag">
                      <p className="Poppins-R grayColor">
                      For the ease of the general public who are not able to afford reliable medical treatments, Shaheed Hakim Mohammed Said introduced the idea of Hamdard Free Mobile Dispensaries service. To begin with, he set up free mobile dispensary in Karachi. numbers increased. Currently, 18 Hamdard Free Mobile Dispensaries are serving the underprivileged (500,000 patients every year) and providing them with free consultations in all major cities of Pakistan. Hamdard intends to enhance and grow their numbers and make this well-known and reachable for the indigent 
                      </p>
                    </div>
                    <b className="Poppins-R greenColor Hamdard_Free_mobile">
                      Hamdard Free Mobile Dispensaries across Pakistan
                    </b>

                    <div className="col-lg-10 col-md-12 mt-3 dispensaries_accordion">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="accordingheader">
                            Karachi
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 1-6</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Hamdard Center, Nazimabad - 3, Karachi
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 021 36616001-4
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="6">
                          <Accordion.Header className="accordingheader">
                            Lahore
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 7-9</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Zonal Office, 142 - Lytton Road, Lahore
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 042-37173336, 37237729
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="10">
                          <Accordion.Header className="accordingheader">
                            Rawalpindi
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 10</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Marier Chowk, Murree Road, Rawalpindi
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 0333-5266823, 051-5120748
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="4">
                          <Accordion.Header className="accordingheader">
                            Peshawar
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 11</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Zonal Ofiice,18 - Saddar Road, Peshawar Cantt
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 91-5274186
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="9">
                          <Accordion.Header className="accordingheader">
                            Quetta
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 12</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>:Mekangi Road, Quetta
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 068-5566471
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="1">
                          <Accordion.Header className="accordingheader">
                            Hyderabad
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 13</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Jail Road, Hyderabad
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 022-2619960, 2618958
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="7">
                          <Accordion.Header className="accordingheader">
                            Sukkur
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 14</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Freere Road, Sukkur
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 071-5806313. 5806312
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="3">
                          <Accordion.Header className="accordingheader">
                            Multan
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 15</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Huzoori Bagh Road, Multan
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 061-4571193, 4571194
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="5">
                          <Accordion.Header className="accordingheader">
                            Sargodha
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 16</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Gole Chowk, Sargodha
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 0483-716132, 711480
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="2">
                          <Accordion.Header className="accordingheader">
                            Faisalabad
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 17</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: Model Town A, Faisalabad
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: 041-2600311, 2600312
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="10">
                          <Accordion.Header className="accordingheader">
                           Azad Kashmir
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Hamdard Free Mobile Dispensary unit 18</b>
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Address</b>: University of Poonch, Azad Kashmir, Rawalakot
                            </p>
                            <p className="Montserrat-SB accordin-size-font">
                              <b>Contact</b>: +92-5824-960008
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileservice">
            <img
              className="servicemobile-img"
              src={service}
              alt="image"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
