import React from "react";
import "./home.css";

import { useNavigate } from "react-router-dom";

export default function BannerGlobal() {
  const navigate = useNavigate();
  return (
    <section className="bgimg banner-galobal">
      <div className="container">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="text-cnetered col-lg-8">
            <div className="text-cnetered">
              <h5 className="Montserrat-M grayColor">
                GLOBAL <b>PRESENCE</b>
              </h5>
            </div>
            <div className="text-cnetered global-content">
              <h2 className="Montserrat-B greenColor">
                Changing lives around the world
              </h2>
            </div>
            <div className="mt-5 container">
              <div className="row">
                <section>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="global-inner-div"
                          data-aos="flip-left"
                          data-aos-delay="500"
                        >
                          <div className="inner-global-heading">
                            <h6 className="h6-global">Presence in</h6>
                            <h1 className="nmbrfont">06</h1>
                            <h6 className="h6-global-2nd">Continents</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="global-inner-div"
                          data-aos="flip-left"
                          data-aos-delay="500"
                        >
                          <div className="inner-global-heading">
                            <h6 className="h6-global">Available in</h6>
                            <h1 className="nmbrfont">30+</h1>
                            <h6 className="h6-global-2nd">Countries</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="global-inner-div"
                          data-aos="flip-left"
                          data-aos-delay="500"
                        >
                          <div className="inner-global-heading">
                            <h6 className="h6-global">0ver</h6>
                            <h1 className="nmbrfont">475</h1>
                            <h6 className="h6-global-2nd">Products</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="global-inner-div"
                          data-aos="flip-left"
                          data-aos-delay="500"
                        >
                          <div className="inner-global-heading">
                            <h6 className="h6-global">Trusted by</h6>
                            <h1 className="nmbrfont">35+</h1>
                            <h6 className="h6-global-2nd">Distributors</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="global-inner-div"
                          data-aos="flip-left"
                          data-aos-delay="500"
                        >
                          <div className="inner-global-heading">
                            <h6 className="h6-global" id="h6-global-id">
                              Best Export
                              <br />
                              Performance Trophy
                            </h6>
                            <h1 className="nmbrfont">
                              39<span style={{ fontSize: "15px" }}>years</span>
                            </h1>
                            <h6 className="h6-global-2nd">Continents</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="global-inner-div"
                          data-aos="flip-left"
                          data-aos-delay="500"
                        >
                          <div className="inner-global-heading">
                            <h6 className="h6-global">Rupees</h6>
                            <h1 className="nmbrfont">01B</h1>
                            <h6 className="h6-global-2nd">Export/year</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="globlbtnsdiv">
            <button onClick={() => navigate('international-busniess')} type="button" className="globalbtn btn btn-light">
              READ MORE
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
