import React,{useEffect} from 'react';
import './mainProducts.css'
import BannerProduct from './bannerProduct';
import Items from './items';

export default function MainProducts() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='mainProducts'>
            <BannerProduct/>
            <Items/>
        </div>
    )
};
