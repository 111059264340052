import React, { useEffect, useRef, useState } from "react";
import right from "../assets/images/right.png";
import left from "../assets/images/left.png";
import greenplus from "../assets/images/roundedgreen.svg";
import products from "../assets/images/products.svg";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import axios from "axios";
import Carousel from "react-elastic-carousel";

export default function Items() {
  const asset_path = "upload/";
  // let gen = "";
  let carousel = useRef(null);
  let carouselOne = useRef(null);
  let carouselTwo = useRef(null);

  let navigate = useNavigate();
  const [data, setdata] = React.useState([]);
  const [dataApiOne, setdataApiOne] = React.useState([]);
  const [dataApiTwo, setdataApiTwo] = React.useState([]);
  const [ApiTags, setApiTags] = useState([]);
  const [searchinp, setsearchinp] = useState("");
  const [gen, setgen] = useState("");

  const [filter, setFilter] = useState({
    gender: "",
    tags: [],
  }); // state for handling filters

  const [clearFilterButtonDisabled, setClearFilterButtonDisabled] =
    useState(true);

  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1 },
    { width: 650, itemsToShow: 3, itemsToScroll: 2 },
    { width: 1440, itemsToShow: 4, itemsToScroll: 2 },
  ];

  const getAllproducts = (categoryid, gender, seperateTags, search) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}public/products?categoryId=${categoryid}&gender=${gender}&tags=${seperateTags}&search=${search}`
      )
      .then(function (response) {
        switch (categoryid) {
          case "1":
            setdata(response?.data?.Products);
            // console.log(response, '====== get All Cat 01 products =====')
            break;
          case "2":
            setdataApiOne(response?.data?.Products);
            // console.log(response, '====== get All Cat 02 products =====')
            break;
          case "3":
            setdataApiTwo(response?.data?.Products);
            // console.log(response, '====== get All Cat 03 products =====')
            break;
          default:
            break;
        }
      })
      .catch(function (error) {
        if (error) {
          if (error.response) {
            if (error.response.data.message[0]) {
              if (error.response.data.message[0] === "no data found") {
                switch (categoryid) {
                  case "1":
                    setdata("No Product Found!");
                    // console.log(response, '====== get All Cat 01 products =====')
                    break;
                  case "2":
                    setdataApiOne("No Product Found!");
                    // console.log(response, '====== get All Cat 02 products =====')
                    break;
                  case "3":
                    setdataApiTwo("No Product Found!");
                    // console.log(response, '====== get All Cat 03 products =====')
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
      });
  };

  React.useEffect(() => {
    getAllproducts("1", gen, "", searchinp);
    getAllproducts("2", gen, "", searchinp);
    getAllproducts("3", gen, "", searchinp);
    getAllTags();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    setFilter({ ...filter, gender: gen });
    setClearFilterButtonDisabled(false);
    getAllproducts("1", gen, filter.tags.join("|"), searchinp);
    getAllproducts("2", gen, filter.tags.join("|"), searchinp);
    getAllproducts("3", gen, filter.tags.join("|"), searchinp);
  }, [gen])

  const HandelClick = (row) => {
    navigate(`/product-details/${row.PKProductId}`, { state: row });
  };

  const getAllTags = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/tags`)
      .then((res) => {
        // console.log(res.data.Tags, 'tags')

        setApiTags(res.data.Tags);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVal = async (e) => {
    setgen(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault()
    console.log(gen, "gender by m")
    setClearFilterButtonDisabled(false);
    getAllproducts("1", gen, filter.tags.join("|"), searchinp);
    getAllproducts("2", gen, filter.tags.join("|"), searchinp);
    getAllproducts("3", gen, filter.tags.join("|"), searchinp);
  };

  const tagId = (e, val) => {
    let isChecked = e.target.checked;
    let id = val.PKTagId;
    let newgender = filter.gender !== "" ? filter.gender : "";

    setClearFilterButtonDisabled(false);
    if (isChecked) {
      let tempArray = [...filter.tags];
      tempArray.push(id.toString());
      setFilter({ ...filter, tags: tempArray });
      getAllproducts("1", newgender, tempArray.join("|"), searchinp);
      getAllproducts("2", newgender, tempArray.join("|"), searchinp);
      getAllproducts("3", newgender, tempArray.join("|"), searchinp);
    } else {
      let filteredTags = filter.tags.filter((e) => e !== id.toString());
      setFilter({ ...filter, tags: filteredTags });
      getAllproducts("1", newgender, filteredTags.join("|"), searchinp);
      getAllproducts("2", newgender, filteredTags.join("|"), searchinp);
      getAllproducts("3", newgender, filteredTags.join("|"), searchinp);
    }
  };

  const handleClearAll = (e) => {
    e.preventDefault();
    e.target.reset();
    setClearFilterButtonDisabled(true);

    setsearchinp("");
    setgen("");
    setFilter({
      gender: "",
      tags: [],
    });
    getAllproducts("1", gen, "", "");
    getAllproducts("2", gen, "", "");
    getAllproducts("3", gen, "", "");
  };

  return (
    <>
      <section className="banner-items" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12"></div>
            <div className="col-lg-9 col-md-12">
              <div>
                <div className="contant-heading">
                  <h5 className="Montserrat-M grayColor">
                    WHAT WE OFFER - <b>PRODUCTS</b>
                  </h5>
                </div>
                <div className="contant3">
                  <h2 className="Montserrat-B greenColor">
                    Leading legacy of quality products
                  </h2>
                </div>
                <div className="greenline-items"></div>
                <div className="contant-ptag">
                  <p className="Poppins-R grayColor">
                    Hamdard has a range of products that are a part of every
                    household in Pakistan. A well-known brand that has been a
                    part of the Pakistani pantry for years. Our products include
                    medicinal and counter-top beverages, medicines, products for
                    overall health, which are created with the finest
                    ingredients under optimal conditions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <form onSubmit={(e) => handleClearAll(e)}>
                <div className="srchbx">
                  <input type="text" placeholder="Search Here..." value={searchinp} onChange={(e) => { setsearchinp(e.target.value) }} />
                  <button className="srchbtn" onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
                    <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
                  </svg>
                  </button>
                </div>
                <div className="check-items mt-5">
                  <div>
                    <h4 className="Montserrat-B greenColor">
                      Product Used For:
                    </h4>

                    {ApiTags.map((val, i) => {
                      return (
                        <Form.Check
                          key={i}
                          style={{ border: "3xp solid #000!important" }}
                          aria-label="option 1"
                          onChange={(e) => tagId(e, val)}
                          label={val.NameOfTag}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="check-items">
                  <div>
                    <h4 className="Montserrat-B greenColor">Gender:</h4>

                    <Form.Check
                      style={{ border: "3xp solid #000!important" }}
                      aria-label="option 1"
                      label="Male"
                      // checked={value}
                      value="m"
                      onChange={(e) => handleVal(e)}
                      name="gender"
                      type="radio"
                    />

                    <Form.Check
                      style={{ border: "3xp solid #000!important" }}
                      aria-label="option 2"
                      label="Female"
                      // checked={value}
                      value="f"
                      onChange={(e) => handleVal(e)}
                      name="gender"
                      type="radio"
                    />

                    <Form.Check
                      style={{ border: "3xp solid #000!important" }}
                      aria-label="option 3"
                      label="Both"
                      // checked={value}
                      name="gender"
                      type="radio"
                      value="b"
                      onChange={(e) => handleVal(e)}
                    />

                    <div style={{ marginTop: "25px" }}>
                      <button
                        type="submit"
                        className="mt-1 mb-3 btn py-2 px-3 clearAllBtn"
                        disabled={clearFilterButtonDisabled}
                        style={{
                          backgroundColor: clearFilterButtonDisabled
                            ? "lightGray"
                            : "#79C32D",
                          borderRadius: 8,
                          color: "white",
                        }}
                      >
                        Clear all filters
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-9 col-md-12">
              <div>
                <div className="item-headings-div">
                  {data !== "No Product Found!" && (
                    <div className="slider-custom-buttons consumer-buttons">
                      <button
                        onClick={() => carousel.slidePrev()}
                        className="slider-custom-button-prev"
                      >
                        <img className="slider-custom-prev" src={left} />
                      </button>
                      <button
                        onClick={() => carousel.slideNext()}
                        className="slider-custom-button-next"
                      >
                        <img className="slider-custom-next" src={right} />
                      </button>
                    </div>
                  )}
                  <div className="events-heading-h2">
                    <h2 className="Montserrat-B greenColor">
                      Consumer Products
                    </h2>
                  </div>
                </div>
                {data === "No Product Found!" ? (
                  <p className="text-danger fs-5 fw-light">No Product Found!</p>
                ) : (
                  <div className="item-slider">
                    <Carousel
                      ref={(ref) => (carousel = ref)}
                      breakPoints={breakPoints}
                      itemPadding={[2, 10]}
                      enableAutoPlay={false}
                      pagination={false}
                      autoPlaySpeed={5000}
                      showEmptySlots
                      showArrows={false}
                    >
                      {data && data.length !== 0
                        ? data.map((row) => (
                          <div className="img-div-events">
                            <div className="item-innerimg-div">
                              <img
                                onClick={() => HandelClick(row)}
                                className="events-item-div"
                                alt=""
                                src={asset_path + row.Image}
                              />
                              <img
                                onClick={() => HandelClick(row)}
                                className="greenplus"
                                alt=""
                                src={greenplus}
                              />
                            </div>

                            <div className="events-items-text">
                              <p>
                                <b>{row.Title}</b>
                              </p>
                            </div>
                          </div>
                        ))
                        : null}
                    </Carousel>
                  </div>
                )}
                <div className="item-headings-div">
                  {dataApiOne !== "No Product Found!" && (
                    <div className="slider-custom-buttons">
                      <button
                        onClick={() => carouselOne.slidePrev()}
                        className="slider-custom-button-prev"
                      >
                        <img className="slider-custom-prev" src={left} />
                      </button>
                      <button
                        onClick={() => carouselOne.slideNext()}
                        className="slider-custom-button-next"
                      >
                        <img className="slider-custom-next" src={right} />
                      </button>
                    </div>
                  )}
                  <div className="events-heading-h2">
                    <h2 className="Montserrat-B greenColor">Medicaments</h2>
                  </div>
                </div>

                {dataApiOne === "No Product Found!" ? (
                  <p className="text-danger fs-5 fw-light">No Product Found!</p>
                ) : (
                  <div className="item-slider">
                    <Carousel
                      ref={(ref) => (carouselOne = ref)}
                      breakPoints={breakPoints}
                      itemPadding={[2, 10]}
                      enableAutoPlay={false}
                      pagination={false}
                      autoPlaySpeed={5000}
                      showEmptySlots
                      showArrows={false}
                    >
                      {dataApiOne && dataApiOne.length !== 0
                        ? dataApiOne.map((row) => (
                          <div className="img-div-events">
                            <div className="item-innerimg-div">
                              <img
                                onClick={() => HandelClick(row)}
                                className="events-item-div"
                                alt=""
                                src={asset_path + row.Image}
                              // src={img2}
                              />
                              <img
                                onClick={() => HandelClick(row)}
                                className="greenplus"
                                alt=""
                                src={greenplus}
                              />
                            </div>

                            <div className="events-items-text">
                              {/* <span>100ml</span> */}
                              <p>
                                <b>{row.Title}</b>
                              </p>
                            </div>
                          </div>
                        ))
                        : null}
                    </Carousel>
                  </div>
                )}

                <div className="item-headings-div">
                  {dataApiTwo !== "No Product Found!" && (
                    <div className="slider-custom-buttons">
                      <button
                        onClick={() => carouselTwo.slidePrev()}
                        className="slider-custom-button-prev"
                      >
                        <img className="slider-custom-prev" src={left} />
                      </button>
                      <button
                        onClick={() => carouselTwo.slideNext()}
                        className="slider-custom-button-next"
                      >
                        <img className="slider-custom-next" src={right} />
                      </button>
                    </div>
                  )}
                  <div className="events-heading-h2">
                    <h2 className="Montserrat-B greenColor">OTC Products</h2>
                  </div>
                </div>

                {dataApiTwo === "No Product Found!" ? (
                  <p className="text-danger fs-5 fw-light">No Product Found!</p>
                ) : (
                  <div className="item-slider">
                    <Carousel
                      ref={(ref) => (carouselTwo = ref)}
                      breakPoints={breakPoints}
                      itemPadding={[2, 10]}
                      enableAutoPlay={false}
                      pagination={false}
                      autoPlaySpeed={5000}
                      showEmptySlots
                      showArrows={false}
                    >
                      {dataApiTwo && dataApiTwo.length !== 0
                        ? dataApiTwo.map((row) => (
                          <div
                            className="img-div-events"
                            onClick={() => HandelClick(row)}
                          >
                            <div className="item-innerimg-div">
                              <img
                                onClick={() => HandelClick(row)}
                                className="events-item-div"
                                alt=""
                                src={asset_path + row.Image}
                              // src={img2}
                              />
                              <img
                                onClick={() => HandelClick(row)}
                                className="greenplus"
                                alt=""
                                src={greenplus}
                              />
                            </div>

                            <div className="events-items-text">
                              {/* <span>100ml</span> */}
                              <p>
                                <b>{row.Title}</b>
                              </p>
                            </div>
                          </div>
                        ))
                        : null}
                    </Carousel>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mobileservice">
          <img
            className="products-img"
            src={products}
            alt="image"
            loading="lazy"
          />
        </div>
      </section>

      {/* <InfiniteCarousel
                    breakpoints={[
                      {
                        breakpoint: 500,
                        settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                        },
                      },
                      {
                        breakpoint: 768,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 1,
                        },
                      },
                    ]}
                    dots={false}
                    showSides={true}
                    sidesOpacity={0.1}
                    sideSize={0}
                    autoCycle={true}
                    cycleInterval={3000}
                    slidesToScroll={1}
                    slidesToShow={3}
                    scrollOnDevice={true}
                  >
                    {data?.map((row) => (
                      <div>
                        <div className="img-div-events">
                          <div className="item-innerimg-div">
                            <img
                              className="events-item-div"
                              alt=""
                              src={con1}
                            />
                          </div>
                          <div onClick={HandelClick} className="greenplus-div">
                            <img className="greenplus" alt="" src={greenplus} />
                          </div>

                          <div className="events-items-text">
                            <div className="events-slider-innner-text-div">
                              <ul className="liststyletype">
                                <li className="Montserrat-B">
                                  <b>Roohafza</b>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteCarousel> */}
    </>
  );
}
