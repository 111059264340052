import React from "react";
import "./home.css";
import Asset19 from "../assets/images/lp/Section-8-HR/02-learning n development.jpg";
import Asset20 from "../assets/images/lp/Section-8-HR/01-opputunity.jpg";
import Asset21 from "../assets/images/lp/Section-8-HR/03-gender.jpg";

import green from "../assets/images/line.png";
import wecare from "../assets/images/wecare.png";
import {useNavigate } from "react-router-dom";

export default function BannerWorkWithUs() {
  const navigate = useNavigate();
  return (
    <section className="banner-two">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="container">
              <div className="mt-5 row">
                <div
                  className="inner-workwithus-heading"
                  data-aos="flip-left"
                  data-aos-once="true"
                >
                  <div className="contant-heading">
                    <h6 className="Montserrat-M grayColor">
                      WORK <b>WITH US</b>
                    </h6>
                  </div>
                  <div className="contantWorkwithus">
                    <h2 className="Montserrat-B greenColor">
                    Remarkable career growth and employment opportunities
                    </h2>
                  </div>
                  <div className="greenline-workwithus"></div>
                  <div className="contant-ptag">
                    <p className="Poppins-R grayColor">
                    Our team is the lifeline of our organization. We strive to enhance individual capabilities, engage, motivate, retain and aim to support & empower them to be the best they can.
                      <br />
                      <br />
                      <h3 className="Montserrat-B greenColor">Our Culture</h3>
                      Working at Hamdard is an exciting experience for entry-level professionals. The diverse cultural mix of our team makes Hamdard an active learning organization. We provide a conducive learning environment to enable them to achieve their full potential.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ zIndex: 10 }} className="col-lg-7 col-md-12">
            <div
              className="container"
              data-aos="flip-right"
              data-aos-once="true"
            >
              <div className=" row">
                <div class="mt-3 col-sm-7">
                  <img className="workimg" src={Asset19} alt="image" />
                </div>
                <div class="col-sm-5">
                  <div class="card-body">
                    <h4 class="Montserrat-B greenColor">
                      <b>
                        Life at <br />
                        Hamdard
                      </b>
                    </h4>
                    <p class="card-text Poppins-R grayColor">
                    A glance at the multiple incentive programmes that Hamdard offers.
                    </p>
                    <div>
                      <button onClick={() => navigate('life-at-hamdard')} type="button" className="workbtn btn btn-light">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-3 col-sm-7">
                  <img className="workimg" src={Asset20} alt="image" />
                </div>
                <div class="col-sm-5">
                  <div class="card-body">
                    <h4 class="Montserrat-B greenColor">
                      <b>
                        Opportunities <br />& openings
                      </b>
                    </h4>
                    <p class="card-text Poppins-R grayColor">
                    Diversifying available leads with talented professionals and ground-breaking opportunities for the masses. 
                    </p>
                    <div>
                      <button onClick={() => navigate('opportunities')} type="button" className="workbtn btn btn-light">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mt-3 col-sm-7">
                  <img className="workimg" src={Asset21} alt="image" />
                </div>
                <div class="col-sm-5">
                  <div class="card-body">
                    <h4 class="Montserrat-B greenColor">
                      <b>
                        Learning <br />& Development
                      </b>
                    </h4>
                    <p class="card-text Poppins-R grayColor">
                    Provide a chance to grow and learn with professionals at Hamdard, in order and enhance and expand career horizons. </p>
                    <div>
                      <button onClick={() => navigate('life-at-hamdard')} type="button" className="workbtn btn btn-light">
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="bottomwhatwecare" >
                        <img className="wecareimg" src={wecare}  alt="image"/>
                    </div> */}
          </div>
          <div className="workwithus-bannertwo-heading-img">
            <img className="wecareimg" src={wecare} alt="image" />
          </div>
        </div>
      </div>
    </section>
  );
}
