import React, { useEffect } from "react";
import img4 from "../assets/images/matab4.webp";
// import img4 from "../assets/images/service4.png";
import img3 from "../assets/images/service3.jpg";
import img2 from "../assets/images/service2.jpg";
import img1 from "../assets/images/service1.png";
import service from "../assets/images/Assett8.svg";
import plus from "../assets/images/pluswhite.svg";
import Cities from "./cities";
import Aos from "aos";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";


export default function Service() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const matab1 = {
    'Title':'Arambagh Matab',
    'Description':'<p>Arambagh Matab holds a unique significance in Hamdard’s history as it was the first Matab opened by the founder of Hamdard Pakistan Shaheed Hakim Mohammed Said on 28 June 1948. This is where the journey of Hamdard Pakistan started and where Shaheed Hakim Mohammed Said pledged to serve Pakistan to the best of his abilities</p>'
  };
  const matab2 = {
    'Title':'Hamdard Markaz Lahore',
    'Description':'<p>Hamdard Markaz Lahore holds a great historical significance. It was here that Shaheed Hakim Mohammed Said used to visit and perform medical checkups during the early years of Hamdard Pakistan. This picture represents the formal inauguration of Hamdard Markaz, Hamdard Conference Centre on the 3rd of June 1998 by then Chief Minister of Punjab Mr. Shahbaz Sharif. Shaheed Hakim Mohammed Said also graced the occasion with his presence and much to the joy of Hamdard workers and citizens of Lahore.</p>'
  };
  const matab3 = {
    'Title':'Pakistan’s first Digital Hamdard Matab (Naimat Begum)',
    'Description':'<p>Hamdard Pakistan inaugurated the country’s first digital Hamdard Matab in the first week of January to further expand its medical facilities network in the country. This digital Matab has computerized records of patients’ diagnoses, prescriptions, and their health follow-ups. In addition, there are a large number of facilities available for patients, including online appointments, ordering medicines online, selection of Tabibs, healthy & stress-free environment, easy access to information, strict implementation of Covid-19’s SOPs, trained staff, and Hakims to serve patients with full attention, care and dedication.</p>'
  };
  const matab4 = {
    'Title':'Naimat Begum Hamdard University Hospital ',
    'Description':'<p>Established on 18 September 2009, in Nazimabad, it is now positioned as a leading healthcare institute, offering a range of medical services to the general public. Operating strategically in the middle of Karachi city, Naimat Begum Hamdard University Hospital, Nazimabad is a symbol of reliability and quality in Pakistan’s health care sector with a vision to provide affordable and state-of-the-art healthcare services without any discrimination of cast or creed, and became the most relevant tertiary healthcare institute for every stratum of the society.</p>'
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState();
  
  const modalFunciton = (data) => {
    setModalData(data);
    setModalShow(true)
  }
  
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalData?.Title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div dangerouslySetInnerHTML={{ __html: modalData?.Description }} />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <section
        className="banner-service"
        data-aos="fade-up"
        data-aos-delay="400"
      >
        <div className="container-bottom-green container">
          <div className="fountdation-secdiv row">
            <div className="text-cnetered col-lg-12">
              <div className="text-cnetered">
                <h5 className="Montserrat-M grayColor">
                  SERVICES - <b>HAMDARD MATAB</b>
                </h5>
              </div>
              <div className="text-cnetered foundation-content">
                <h2 className="Montserrat-B greenColor">
                Taking a step further in curing people - Devising solutions for a healthier and richer life.
                </h2>
              </div>
              <div className="before-greenline">
                <div className="greenline-service-div"></div>
              </div>
              <div className="text-cnetered mt-4">
                <p className="Poppins-R grayColor">
                Hamdard started its healthcare services through Hamdard Matab on June 26, 1948, in Karachi. Later on, the network expanded to Rawalpindi, Lahore, and Peshawar. At Hamdard Matab, patients are requested to pay for their medicines only, consultation and prescription are free of cost for all. 
                </p>
              </div>
              <div
                className="bottom-bannertwo-heading-img"
                style={{ zIndex: 1 }}
              >
                <img
                  className="service-img"
                  src={service}
                  alt="image"
                  loading="lazy"
                />
              </div>
              <div className="mt-5 mb-5 container">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div class="card" data-aos="flip-left" data-aos-delay="400">
                      <div
                        onClick={() => modalFunciton(matab1)}
                        class="card-headers">
                        <img
                          src={img1}
                          alt="rover"
                          loading="lazy"
                          className="scaleimg"
                        />
                        <div className="fundation-text-div">
                          <div className="plus-div">
                            <img
                              classname="pluswhite-logo"
                              src={plus}
                              alt="plus"
                              loading="lazy"
                            />
                          </div>
                            <h5 className="Montserrat-M"><b>{matab1?.Title}</b></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div class="card" data-aos="flip-left" data-aos-delay="400">
                      <div 
                        onClick={() => modalFunciton(matab2)}
                        class="card-headers">
                        <img
                          src={img2}
                          alt="rover"
                          loading="lazy"
                          className="scaleimg"
                        />
                        <div className="fundation-text-div">
                          <div className="plus-div">
                            <img
                              classname="pluswhite-logo"
                              src={plus}
                              alt="plus"
                              loading="lazy"
                            />
                          </div>
                            <h5 className="Montserrat-M"><b>{matab2?.Title}</b></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div class="card" data-aos="flip-left" data-aos-delay="400">
                      <div 
                        onClick={() => modalFunciton(matab3)}
                        class="card-headers">
                        <img
                          src={img3}
                          alt="rover"
                          loading="lazy"
                          className="scaleimg"
                        />
                        <div className="fundation-text-div">
                          <div className="plus-div">
                            <img
                              classname="pluswhite-logo"
                              src={plus}
                              alt="plus"
                              loading="lazy"
                              className="scaleimg"
                            />
                          </div>
                          <h5 className="Montserrat-M"><b>{matab3?.Title}</b></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div class="card" data-aos="flip-left" data-aos-delay="400">
                      <div 
                        onClick={() => modalFunciton(matab4)}
                        class="card-headers">
                        <img
                          src={img4}
                          alt="rover"
                          loading="lazy"
                          className="scaleimg"
                        />
                        <div className="fundation-text-div">
                          <div className="plus-div">
                            <img
                              classname="pluswhite-logo"
                              src={plus}
                              alt="plus"
                              loading="lazy"
                              className="scaleimg"
                            />
                          </div>
                          <h5 className="Montserrat-M"><b>{matab4?.Title}</b></h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="citiesin-service">
            <Cities />
          </div>
        </div>
      </section>
    </>
  );
}
