import React,{useEffect} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import thumb1 from "../../assets/images/About us/Board of Directors/01thumbnails_.jpg";
import thumb2 from "../../assets/images/About us/Board of Directors/02thumbnails.jpg";
import thumb3 from "../../assets/images/About us/Board of Directors/03thumbnails.jpg";
import thumb4 from "../../assets/images/About us/Board of Directors/04thumbnails.jpg";
import thumb5 from "../../assets/images/About us/Board of Directors/farrukhthumb.jpg";
import InfiniteCarousel from "react-leaf-carousel";
import { useNavigate } from "react-router-dom";
import Aos from 'aos';

export default function Directorsslider() {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({duration:1500});
  },[]);

  return (
    <section className="bord-sec">
    <div className="container">
      <div className="row">
        <div className="text-center mb-5">
          <h3 className="Poppins-R grayColor">
           <b>Our Leadership</b>
          </h3>
        </div>
      </div>
    </div>
    
    <InfiniteCarousel
      breakpoints={[
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1397,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ]}
      dots={true}
      // showSides={true}
      sideSize={0}
      //dots={false}
      slidesToScroll={1}
      slidesSpacing={0}
      autoCycle={true}
      cycleInterval={3000}
      slidesToShow={3}
      scrollOnDevice={true}
    >
      <div>
        <div onClick={() =>  navigate("/director/hakim")} className="director-slider-items text-center">
          <img src={thumb1} className="innerwhoweare-lastbanner" />
          <div className="text-center">
            <h5 className="Montserrat-B greenColor mt-4">Shaheed Hakim Mohammed Said</h5>
            <h5 className="Montserrat-M greenColor">Founder</h5>
          </div>
        </div>
      </div>
      <div>
        <div onClick={() =>  navigate("/director/sadia")} className="director-slider-items text-center">
          <img src={thumb2} className="innerwhoweare-lastbanner" />
          <div className="text-center">
            <h5 className="Montserrat-B greenColor mt-4">Ms Sadia Rashid</h5>
            <h5 className="Montserrat-M greenColor">Chairperson</h5>
          </div>
        </div>
      </div>
      <div>
        <div onClick={() =>  navigate("/director/fatema")} className="director-slider-items text-center">
          <img src={thumb3} className="innerwhoweare-lastbanner" />
          <div className="text-center">
            <h5 className="Montserrat-B greenColor mt-4">Ms Fatema Munir Ahmed</h5>
            <h5 className="Montserrat-M greenColor">Managing Director & CEO</h5>
          </div>
        </div>
      </div>
      {/* <div>
        <div onClick={() =>  navigate("/director/arshad")} className="director-slider-items text-center">
          <img src={thumb4} className="innerwhoweare-lastbanner" />
          <div className="text-center">
            <h5 className="Montserrat-B greenColor mt-4">Dr. Arshad Saleem</h5>
            <h5 className="Montserrat-M greenColor">COO</h5>
          </div>
        </div>
      </div> */}

      <div>
        <div onClick={() =>  navigate("/director/farrukh")} className="director-slider-items text-center">
          <img src={thumb5} className="innerwhoweare-lastbanner" />
          <div className="text-center">
            <h5 className="Montserrat-B greenColor mt-4">Mr. Farrukh Imdad</h5>
            <h5 className="Montserrat-M greenColor">Chief Investment Officer</h5>
          </div>
        </div>
      </div>
    
    </InfiniteCarousel>
  </section>
  );
}
