import React,{useEffect,useState} from "react";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import Man from "../assets/images/train.png";
import "./ib.css";
import service1 from "../assets/images/service1.png";
import bat from "../assets/images/Global Presence/001-award.jpg";
import bat2 from "../assets/images/Global Presence/002-award.jpg";
import bat3 from "../assets/images/Global Presence/003-award.jpg";
import bat4 from "../assets/images/Global Presence/004-award.jpg";
import collage1 from "../assets/images/Global Presence/section-2 collage-01.jpg";
import collage2 from "../assets/images/Global Presence/section-2 collage-02.jpg";
import collage3 from "../assets/images/Global Presence/section-2 collage-03.jpg";
import collage4 from "../assets/images/Global Presence/section-2 collage-04.jpg";
import section1 from "../assets/images/lp/GP-PIC-1.webp";
import section2 from "../assets/images/lp/GP-PIC-3.jpg";
import { Card } from "react-bootstrap";
import doc from "../assets/images/doctest.png";
import InfiniteCarousel from "react-leaf-carousel";
import Aos from "aos";
import bannerimg from "../assets/images/Global Presence/box-Banner.jpg";
import bannerimg1 from "../assets/images/Global Presence/top banner.jpg";
import Socialicons from '../socialIcons';
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import SecondBannerCompo from "../SecondBannerCompo";

export default function Ib() {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img src={bat} onDragStart={handleDragStart} />,
    <img src={bat} onDragStart={handleDragStart} />,
    <img src={bat} onDragStart={handleDragStart} />,
  ];



  const [error, setError] = useState("");
  const [dataSucess, setDataSucess] = useState("");
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false)

  const [contactFormData, setContactFormData] = useState({})
  
  const handelClick = (e) => {
    e.preventDefault()
    e.target.reset()
    setDisable(true)
    setLoader(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/public/contactUs`,
      data: contactFormData,
    })
      .then((res) => {
          setDataSucess(res?.data?.message[0]);
          setLoader(false)
          e.target.reset()
          setDisable(false)
        
      })
      .catch((err) => {
          setError(err?.response?.data?.message[0]);
          setLoader(false);
          setDisable(false)
      });
  };

  const onChangeHandle = (e) =>{
    const name = e.target.name
    const val  = e.target.value

    setError('')
    setDataSucess('')


    setContactFormData({...contactFormData,[name]:val})
  }








  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
                Modal heading
              </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h4>Lorem ipsum </h4>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
            ullamcorper suscipit lobortis nisl ut aliquip ex ea co consequat.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="wrapper_ib">
       <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
     <section>
        <div className="banner-div">
          <div className="empty-section-res"></div>
          <Socialicons value={"white"}/>
          <div className="img-div-whatweoffer">
            <img
              src={bannerimg1}
              className="bannerimg-whatwe-offer"
              alt="bannerimg"
              // loading="lazy"
            />
          </div>
        </div>
      </section>
      <section className="whoweare international-busniess">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="banner-sliderleft-well">
               
              <SecondBannerCompo
                  mainText={
                    <>
                     International Business - An Impact around the Globe
                    </>
                  }
                  classNameBorder="  international_Green_Border"
                  classNameMainDiv="  international_Main_Div"
                />
              
              </div>
            </div>
          </div>
        </div>
                     
      </section>

      <section className="banner-1920-ib">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4 col-md-12" data-aos="fade-up">
              <img src={section1} className="img-fluid" />
            </div> */}

            <div className="col-lg-5 col-md-12" data-aos="flip-right">
              <div className="about-img-bg">
                <img className="imgib" src={section1} alt="image" />
                <div className="about-img-testing"></div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12" data-aos="fade-up">
              <div className="banner1920-heading-contant">
                <div className="contant-ptag">
                  <p className="Poppins-R" style={{ color: "#003634" }}>
                  Hamdard Pakistan has reached across borders and beyond, around the globe to millions of consumers. To significantly increase the export strategy, our teams are looking for new markets and spaces where we can introduce our products. To comply with quality standards, Hamdard Laboratories have confirmed the highest regulatory requirement; ISO 9001:2015 and ISO 22000:2005
                  </p>
                  <p className="Poppins-R" style={{ color: "#003634" }}>
                  We stride forward and pace endlessly to create an impactful presence of our products around the globe.
                  </p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <div className="emty-section"></div>
      <section className="ibteam"  data-aos="fade">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              {/* <div className="slider-social-icons-director">
                <a href="#">
                  <Fb className="fbicontwo" />
                </a>
                <a href="#">
                  <Twitter className="twittericontwo" />
                </a>
                <a href="#">
                  <Insta className="instaicontwo" />
                </a>
                <a href="#">
                  <Youtube className="yticontwo" />
                </a>
              </div> */}
              <div className="mettteam">
                <div>
                  <h1 className="Montserrat-M grayColor">International</h1>
                </div>
                <div className="">
                  <h2 className="Montserrat-B greenColor">Business</h2>
                </div>
                <div className="greenline-ib"></div>
                <div className="contant-ptag">
                  <form onSubmit={(e)=>handelClick(e)}>
                    <div class="row">
                      <div class="col">
                        <input
                        required
                          type="text"
                          class="form-control"
                          placeholder="Full Name"
                          name="FullName"
                          onChange={(e)=>onChangeHandle(e)}
                        />
                      </div>
                      <div class="col">
                        <input
                        required
                          type="email"
                          class="form-control"
                          placeholder="Email"
                          name="Email"
                          onChange={(e)=>onChangeHandle(e)}
                        />
                      </div>
                      <div class="col">
                        <input
                          required
                          type="number"
                          class="form-control"
                          placeholder="Contact Number"
                          name="ContactNumber"
                          onChange={(e)=>onChangeHandle(e)}
                        />
                      </div>
                    </div>
                    <div class="mt-4 form-group">
                      <textarea
                      required
                      minLength={20}
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Message"
                        rows="3"
                        name="Message"
                          onChange={(e)=>onChangeHandle(e)}
                      ></textarea>
                    </div>
                    <div>
                      <p className="text-success">{dataSucess && dataSucess}</p>
                      <p className="text-danger">{error && error}</p>
                    </div>
                    <div className="ib-bottom-btn">
                      <button type="submit"

                       className="custmbtn-ib">
                        {loader ? 'Sending Message' : dataSucess ? 'Message Sent' : 'Contact Us'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5 col-md-12">
              <div className="banner-ib-img"><img src={bannerimg} className="img-fluid"></img></div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-ib banner-one">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 mt-3"  data-aos="flip-left">
                    <div className="">
                      <img src={collage1} alt="" className="ibimgdiv" />
                      <h2
                        className="Montserrat-B greenColor mt-2"
                        style={{ paddingLeft: "30px" }}
                      ></h2>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 mt-3" data-aos="flip-right">
                    <div className="">
                      <img src={collage2} alt="" className="ibimgdivtwo" />
                      <h2
                        className="Montserrat-B greenColor mt-2"
                        style={{ paddingLeft: "30px" }}
                      ></h2>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 mt-3" data-aos="flip-left">
                    <div className="">
                      <img src={collage3} alt="" className="ibimgdivthree" />
                      <h2
                        className="Montserrat-B greenColor mt-2"
                        style={{ paddingLeft: "30px" }}
                      ></h2>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 mt-3" data-aos="flip-right">
                    <div className="">
                      <img src={collage4} alt="" className="ibimgdiv" />
                      <h2
                        className="Montserrat-B greenColor mt-2"
                        style={{ paddingLeft: "30px" }}
                      ></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-md-12 col-lg-5"  data-aos="fade-up">
              <div className="ib-left-section">
                <div className="contant">
                  <h2 className="Montserrat-B greenColor">Overview</h2>
                </div>
                
              </div>
              <div className="about-left-section-ib">
                <div className="contant mt-5">
                  <h4 className="Montserrat-B greenColor">International Product Catalog </h4>
                </div>
                <div className="contant-ptag">
                  <p className="Poppins-R grayColor">
                  Our products are available globally and comply with international markets and safety standards. We have introduced a wide range of Rooh-Afza, herbal products, and supplements worldwide. 
                  </p>
                  <p className="Poppins-R grayColor">
                  We have a strong presence in GCC countries through a network of our distributors and as of today Rooh Afza and other Hamdard products are now available in every major retail chain in GCC like Carrefour, Lulu Supermarkets, Choithram, etc.
                  </p>
                  <p className="Poppins-R grayColor">
                  In the North American region, we have established a strong presence in both USA & Canada with our flagship product Rooh Afza available on shelves at all the major retailers e.g. Walmart, Costco, etc
                  </p>
                  <p className="Poppins-R grayColor">
                  In the UK and Australia Rooh Afza and other Hamdard, products are readily available on large retailers like Tesco, Asda, Sainsbury in the UK, and Woolworths in Australia.

                    We are constantly expanding our market reach and product offering. We plan to increase our export destinations to 50 by 2025.

                  </p>
                  <p className="Poppins-R grayColor">
                  We are also planning to enter international E-commerce through the Amazon platform and aim to develop this as one of our main channels of internal sales. We aim to first start our E-commerce sales in North America and then expand it to other international destinations gradually. 
                  </p>
                  {/* <p className="Poppins-R grayColor">
                    Our products are available globally and comply with international markets and its their safety standards.
                    We have introduced a wide range of Rooh-Afza, herbal products and supplements worldwide. 
                  </p> */}
                </div>
              </div>
              {/* <div className="about-left-section-ib">
                <div className="contant mt-5">
                  <h4 className="Montserrat-B greenColor">International Brand Recognition</h4>
                </div>
                <div className="contant-ptag">
                  <p className="Poppins-R grayColor">
                      To commemorate Pakistan's 71st Independence Day,
                      Rooh Afza once again went global in a bid to pay tribute to the homeland by
                      flashing colors of Pakistan at two of the most popular destinations,
                      London Underground Stations and iconic Eaton Centre, Toronto.
                      People of Canada and Britain also enjoyed the refreshing taste of Rooh Afza,
                      the King of drinks.

                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="ibimg banner-galobal">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="text-cnetered col-lg-8"  data-aos="fade-up">
              {/* <div className="text-cnetered">
                                    <h5 className="Montserrat-M grayColor">GLOBAL <b>PRESENCE</b></h5>
                                </div> */}
              <div className="text-cnetered global-content">
                <h2 className="Montserrat-B greenColor">Statistics</h2>
              </div>
              <div className="mt-5 container">
                <div className="row">
                  <section>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4 col-md-6">
                          <div className="global-inner-div">
                            <div className="inner-global-heading">
                              <h6 className="h6-global">Presence in</h6>
                              <h1 className="nmbrfont">06</h1>
                              <h6 className="h6-global-2nd">Continents</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="global-inner-div">
                            <div className="inner-global-heading">
                              <h6 className="h6-global">Available in</h6>
                              <h1 className="nmbrfont">30+</h1>
                              <h6 className="h6-global-2nd">Countries</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="global-inner-div">
                            <div className="inner-global-heading">
                              <h6 className="h6-global">0ver</h6>
                              <h1 className="nmbrfont">475</h1>
                              <h6 className="h6-global-2nd">Products</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="global-inner-div">
                            <div className="inner-global-heading">
                              <h6 className="h6-global">Trusted by</h6>
                              <h1 className="nmbrfont">35+</h1>
                              <h6 className="h6-global-2nd">Distributors</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="global-inner-div">
                            <div className="inner-global-heading">
                              <h6 className="h6-global" id="h6-global-id">
                                Best Export
                                <br />
                                Performance Trophy
                              </h6>
                              <h1 className="nmbrfont">
                                39
                                <span style={{ fontSize: "15px" }}>years</span>
                              </h1>
                              <h6 className="h6-global-2nd">Continents</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="global-inner-div">
                            <div className="inner-global-heading">
                              <h6 className="h6-global">Rupees</h6>
                              <h1 className="nmbrfont">01B</h1>
                              <h6 className="h6-global-2nd">Export/year</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            {/* <div className="globlbtnsdiv">
              <button type="button" className="globalbtn btn btn-light">
                READ MORE
              </button>
            </div> */}
          </div>
        </div>
      </section>

      <section className="">
        <div className="mt-5 container">
          <div className="row">
            <div className="text-cnetered mt-5"  data-aos="fade-up">
              <h2 className="Montserrat-B ">International Brand Recognition</h2>
            </div>
            <div className="quality-heading-contant m-3 row" data-aos="fade-up">
              <div className="col-lg-8">
              <ul className="">
                <li>
                  <p className="Poppins-R">
                  We are the recipient of the ‘Best Export Performance Trophy’ for 22 consecutive years  <br/> from the Federation of Pakistan Chamber of Commerce and Industry (FPCCI).
                  </p>
                </li>
                <li>
                  <p className="Poppins-R">
                  We have been awarded the ‘consumer choice award’, 14 times consecutively.
                  </p>
                </li>
                <li>
                  <p className="Poppins-R">
                  We have received ‘Brand of the Year’ award for 10 years. 
                  </p>
                </li>
              </ul>
              </div>
              <div className="col-lg-4 col-md-12" data-aos="flip-right">
              <div className="about-img-bg">
                <img className="imgib" src={section2} alt="image" />
                <div className="about-img-testing"></div>
              </div>
            </div>
            </div>
            <InfiniteCarousel
              breakpoints={[
                {
                  breakpoint: 500,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    
                  },
                },
                {
                  breakpoint: 993,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1397,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
              ]}
              dots={true}
              showSides={true}
              sideSize={0}
              // dots={false}
              slidesToScroll={1}
              slidesSpacing={11}
              autoCycle={true}
              cycleInterval={3000}
              slidesToShow={4}
              scrollOnDevice={true}
            >
              <div  data-aos="fade-up" className="ibawardslider">
                <Card  style={{ width: "19rem", marginTop: "50px",marginBottom:"50px",cursor:"pointer"}}>
                  <Card.Img variant="top" src={bat} />
                  <Card.Body>
                    <Card.Text>
                      <p style={{ fontSize: "13px" }} className="Poppins-R">
                      Merit Export Award
                      </p>
                      <p
                        style={{ fontSize: "12px", marginTop: "-16px" }}
                        className="Poppins-R grayColor"
                      >
                        Hamdard received Achievement Merit Export Award of Rooh Afza by Federation of Pakistan Chambers of Commerce and Industry (FPCCI).
                      </p>
                    </Card.Text>
                  
                  </Card.Body>
                </Card>
              </div>
              
              <div  data-aos="fade-up" className="ibawardslider">
                <Card  style={{ width: "19rem", marginTop: "50px",marginBottom:"50px",cursor:"pointer"  }}>
                  <Card.Img variant="top" src={bat2} />
                  <Card.Body>
                    <Card.Text>
                      <p style={{ fontSize: "13px" }} className="Poppins-R">
                        Achievement Award
                      </p>
                      <p
                        style={{ fontSize: "12px", marginTop: "-16px" }}
                        className="Poppins-R grayColor"
                      >
                        Hamdard received Achievement Award for Export of Rooh Afza by Federation of Pakistan Chambers of Commerce and Industry (FPCCI), for the year of 2018-19.
                      </p>
                    </Card.Text>
                  
                  </Card.Body>
                </Card>
              </div>
              
              <div  data-aos="fade-up" className="ibawardslider">
                <Card style={{ width: "19rem", marginTop: "50px",marginBottom:"50px",cursor:"pointer"  }}>
                  <Card.Img variant="top" src={bat3} />
                  <Card.Body>
                    <Card.Text>
                      <p style={{ fontSize: "13px" }} className="Poppins-R">
                        Export Award Certificate 
                      </p>
                      <p
                        style={{ fontSize: "12px", marginTop: "-16px" }}
                        className="Poppins-R grayColor"
                      >
                        Hamdard received Achievement 44th Award for Export
                        of Rooh Afza by Federation of Pakistan Chambers of Commerce and Industry (FPCCI), for the year of 2021. 
                      </p>
                    </Card.Text>
                  
                  </Card.Body>
                </Card>
              </div>
              
              <div  data-aos="fade-up" className="ibawardslider">
                <Card  style={{ width: "19rem", marginTop: "50px",marginBottom:"50px",cursor:"pointer"  }}>
                  <Card.Img variant="top" src={bat4} />
                  <Card.Body>
                    <Card.Text>
                      <p style={{ fontSize: "13px" }} className="Poppins-R">
                        Merit Export Award 
                      </p>
                      <p
                        style={{ fontSize: "12px", marginTop: "-16px" }}
                        className="Poppins-R grayColor"
                      >
                        Hamdard received Achievement Merit Export Award of Rooh Afza by Federation of Pakistan Chambers of Commerce and Industry (FPCCI). for the year of 2014-15
                      </p>
                    </Card.Text>
                  
                  </Card.Body>
                </Card>
              </div>
              
            </InfiniteCarousel>
          </div>
        </div>
      </section>
    </div>
  );
}
