import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Man from '../assets/images/Asset13.png';
import img1 from '../assets/images/Our responsibility/04 - Ration Dist/001.jpg';
import img2 from '../assets/images/Our responsibility/04 - Ration Dist/002.jpg';
import img3 from '../assets/images/Our responsibility/04 - Ration Dist/003.jpg';
import greensolid from '../assets/images/Assetsolid.png';
import search from '../assets/images/search.svg';
import { ReactComponent as Fb } from '../assets/images/fb.svg';
import { ReactComponent as Insta } from '../assets/images/insta.svg';
import { ReactComponent as Twitter } from '../assets/images/twitter.svg';
import { ReactComponent as Youtube } from '../assets/images/youtube.svg';
import '../Slider/slider.css';

export default function SecleftimgRightheading() {
    return (
        <div className="rightimgleftheading">
            <div >
                <section className="banner-sliderleftimg">
                    <div className="container bg-white">
                        <div className="row" data-aos="fade-up">
                            <div className="paddingsliderimg col-lg-5 col-md-12 ourRes_slider_center" >
                                <div className="">
                                    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                        <img src={img1} className="" />
                                        <img src={img2} className="" />
                                        <img src={img3} className="" />
                                    </Carousel>
                                </div>
                            </div>
                            <div className="p-5 col-lg-7 col-md-12 ourRes_slider_center" >
                                
                                      
                                                <h2 className="Montserrat-B greenColor">Flood Relief Camps</h2>
                                           
                                            <div className="sliderleftwala-ptag">
                                                <p className="Poppins-R">Hamdard Foundation Pakistan has set up several “Relief Camps” to cope with the emergency caused by heavy monsoon rain in Sindh province. These camps were for consecutive 15 days, 06 camps per day, provided much-needed medical assistance to the affected communities with free medical check-ups and medicines These camps were equipped with Unani as well as Allopathic medicines and qualified Physicians, Hakims were present at sites for diagnose the patients.</p>
                                            </div>
                                        
                                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
