import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Man from '../assets/images/Asset13.png';
import img1 from '../assets/images/Our responsibility/03 - Heat Wave Activity/001.jpeg';
import img2 from '../assets/images/Our responsibility/03 - Heat Wave Activity/002.jpeg';
import img3 from '../assets/images/Our responsibility/03 - Heat Wave Activity/003.jpeg';
import greensolid from '../assets/images/Assetsolid.png';
import search from '../assets/images/search.svg';
import { ReactComponent as Fb } from '../assets/images/fb.svg';
import { ReactComponent as Insta } from '../assets/images/insta.svg';
import { ReactComponent as Twitter } from '../assets/images/twitter.svg';
import { ReactComponent as Youtube } from '../assets/images/youtube.svg';
import '../Slider/slider.css';

export default function RightimgLeftheading() {
    return (
        <div className="rightimgleftheading" >
            <div>
                <section className="banner-sliderleftimg">
                    <div className="container bg-white">
                        <div className="row" data-aos="fade-up">
                            <div className=" col-lg-7 col-md-12 p-5 ourRes_slider_center" >
                                
                                      
                                                <h2 className="Montserrat-B greenColor">Heat Wave Activity</h2>
                                            
                                            <div className="sliderleftwala-ptag">
                                                <p className="Poppins-R">For the last few years, Southern Pakistan has been severely affected by heatwaves when the temperature has risen to over 50 degrees. Countless people were admitted to the hospital and high numbers of deaths were recorded. At this time of despair, Hamdard Pakistan is fulfilling Shaheed Hakim Mohammed Said’s dream to help humanity by acknowledging its Corporate Social Responsibility to serve Rooh Afza drink on the streets of Karachi & Lahore among the general public. Rooh Afza helped them regain the energy that had been lost because of extreme heat.
                                                    <br></br>
                                                    <br></br>
                                                    <br></br>
                                                    Hamdard served over 7,000 glasses daily in Lahore and 10,000 glasses in Karachi for continuous 30 days at different landmarks
                                                    <br></br>
                                                    <br></br>

                                                    <i>“Hamdard is staying true to the legacy and vision of Shaheed Hakim Mohammed Said by serving Humanity”</i>
                                                </p>
                                            
                                      
                                </div>
                            </div>
                            <div className="paddingsliderimg col-lg-5 col-md-12 align-self-xl-center ourRes_slider_center" >
                                <div className="">
                                    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                        <img src={img1} className="" />
                                        <img src={img2} className="" />
                                        <img src={img3} className="" />
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
