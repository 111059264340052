import React from 'react';
import events from '../assets/images/events-img.svg';

export default function Events() {
    return (
        <section className="publication-events">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-6">
                        <div className="publication-events-heading">
                            <h1 className="Montserrat-B greenColor">Events</h1>
                        </div>
                    </div>
                </div>
            </div>   
            <div className="events-img">
                <img src={events} alt ="" />
            </div>
        </section>    
    )
}
