import React,{useEffect} from 'react';
import Banner from './banner';
import OurVision from './ourVision';
import YearsBanner from './yearsBanner';
import MountainBanner from './mountainBanner';
import Footer from '../Footer/footer';
import './AboutHamdard.css'
import Quality from './quality';
import OurHistory from './ourHistory';

export default function MainAboutHamdard() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      
    return (
        <div className='about-page'>
            <Banner />
            <OurVision/>
            <YearsBanner/>
            <MountainBanner/>
            <OurHistory/>
            <Quality/>
            {/* <Footer/> */}
        </div>
    )
}
