import React, { useEffect } from "react";
import "../AboutHamdard.css";
import "./directors.css";
import { ReactComponent as Fb } from "../../assets/images/fb.svg";
import { ReactComponent as Insta } from "../../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/images/youtube.svg";

import man from "../../assets/images/About us/Board of Directors/farrukh.jpeg";
import man2 from "../../assets/images/About us/Board of Directors/farrukh.jpeg";
import directorslider from "./directorslider";

import Aos from "aos";
import bannerimg from "../../assets/images/About us/Board of Directors/banner.jpg";
import Directorsslider from "./directorslider";
import Socialicons from "../../socialIcons";
import SecondBannerCompo from "../../SecondBannerCompo";

export default function Farrukh() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="page-leadership">
        <section>
          <div className="banner-div">
            <div className="empty-section-res"></div>
            <Socialicons className="hakim" value={"white"} />
            <div className="img-div-whatweoffer">
              <img
                src={bannerimg}
                className="bannerimg-whatwe-offer"
                alt="bannerimg"
                loading="lazy"
              />
            </div>
          </div>
        </section>
        <section className="whoweare">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <SecondBannerCompo
                  mainText={
                    <>
                      
                      Our leadership team drives our <br></br>
                      institution, guides our strategy and <br></br>
                      leads our people.<br></br>
                    </>
                  }

                  classNameBorder="  mutawallis_Green_Border"
                  classNameMainDiv="  hakim_Main_Div"

                />
              </div>
            </div>
          </div>
        </section>

        <section className="whoweare p-5">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <img src={man} className="innerwhoweare-img col-8 offset-1" />
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5 col-md-12">
                <div className="whoweare-contant">
                  <h2 className="Montserrat-B greenColor">
                   Mr. Farrukh Imdad
                  </h2>

                  <h4 className="Montserrat greenColor">Chief Investment Officer, Hamdard Pakistan</h4>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </section>

        <section className="bg-abouthamdard banner-one onemore mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6" data-aos="flip-left">
                <div className="about-left-section-ib">
                  <div className="contant">
                    <h2 className="Montserrat-B greenColor">
                      LOVE PAKISTAN – BUILD PAKISTAN
                    </h2>
                  </div>
                  <div className="contant-ptag-ib">
                    <p className="Poppins-R grayColor">
                    Farrukh Imdad is the Chief Investment Officer of Hamdard Laboratories Pakistan, a group active in fields of Health, Education and Philanthropy.
                      <br></br>
                      <br></br>
                      He joined Hamdard in 2008 and was elevated to the role of Director General, Hamdard Foundation Pakistan, the Philanthropic arm of the group. He also serves as Dean Administration Hamdard University, one of the largest private sector universities in Pakistan. In addition to all these roles he is also the                              Chief Investment Officer of Hamdard Laboratories Waqf Pakistan, the largest herbal manufacturers with exports of its products to over 50 countries globally.
                      <br></br>
                      <br></br>
                      Prior to joining Hamdard, he worked eight years in different overseas markets in the Banking and Telecom Sector.<br></br>
                      <br></br>
                      He has a Master’s Degree in Business Administration from Bahria University, Karachi. 
                    </p>
                  </div>
                </div>
              </div>

        
            </div>
          </div>
        </section>
      </section>
      <Directorsslider />
    </>
  );
}
