import React from 'react'
import services from '../assets/images/building.png'

export default function OurHistory() {
    return (
        <section className="ourHistory-banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-5">
                        <div className="container">
                            <div className="row">
                                <div className="ourHistory-heading-contant" data-aos="flip-left">
                                    <div className="contant-heading">
                                        <h5 className="Montserrat-M grayColor">OUR <b>HISTORY</b></h5>
                                    </div>
                                    <div className="contant2">
                                        <h2 className="Montserrat-B ">From a small Matab, to a <br/>
                                                worldwide leader in Herbal <br/>
                                                Products, Hamdard <br/>
                                                continues to write history. </h2>
                                    </div>
                                    <div className="greenline-bannertwo"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-7">
                    </div>
                </div>
            </div>     
            <div className="bottomcheck">
            <div className="container-fluid">
                <div className="row">

                <div className="col-md-12 col-lg-1">
                        </div>
                        <div className="col-md-12 col-lg-2">
                            <div className="left-green-one" data-aos="fade-up">
                                <div className="inner-ourVision-contant">
                                    <p className="Poppins-SB grayColor line-hight-inner-ourvision">Aram Bagh</p>
                                    <p className="Poppins-B greenColor line-hight-inner-ourvision">First Matab</p>
                                    <h1 className="Montserrat-M grayColor" style={{fontSize:"49.03pt"}}>1948</h1>
                                </div>
                            </div>
                        </div>

                        <div  className="col-md-12 col-lg-2">
                        <div className="left-green-two" data-aos="fade-up">
                                <div className="inner-ourVision-contant">
                                    <p className="Poppins-SB grayColor line-hight-inner-ourvision">Al-Rabia Hamdard </p>
                                    <p className="Poppins-B greenColor ">First Manufacturing Unit</p>
                                    <h1 className="Montserrat-M grayColor" style={{fontSize:"49.03pt"}}>1953  </h1>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-2">
                            <div className="left-green-three" data-aos="fade-up">
                                <div className="inner-ourVision-contant">
                                    <p className="Poppins-SB grayColor line-hight-inner-ourvision">Hamdard Tibbia College </p>
                                    <p className="Poppins-B greenColor ">It was inaugurated by Madr-e- Millat (Fatima Jinnah). </p>
                                    <h1 className="Montserrat-M grayColor" style={{fontSize:"49.03pt"}}>1958  </h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-2">
                            <div className="left-green-four" data-aos="fade-up">
                                <div className="inner-ourVision-contant">
                                    <p className="Poppins-SB grayColor line-hight-inner-ourvision">Madina-tul-Hikmat</p>
                                    <p className="Poppins-B greenColor ">First-of-its-kind university of knowledge and wisdom.</p>
                                    <h1 className="Montserrat-M grayColor" style={{fontSize:"49.03pt"}}>1983</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-2">
                            <div className="left-green-five" data-aos="fade-up">
                                <div className="inner-ourVision-contant">
                                    <p className="Poppins-SB grayColor line-hight-inner-ourvision">Bait-Ul-Hikmat Library</p>
                                    <p className="Poppins-B greenColor ">It is one of the largest state-of-the-art, privately owned, and operated libraries in Pakistan.</p>
                                    <h1 className="Montserrat-M grayColor" style={{fontSize:"49.03pt"}}>1989 </h1>
                                </div>
                            </div>
                        </div>
                      
                        <div className="col-md-12 col-lg-1">
                        </div>
                    </div>
                </div>
            </div>   
            <img className="banner-ourvision-bottom-img" src={services}  alt="image"/> 
        </section>
    )
}
