import React from "react";
import img1 from "../assets/images/release1.svg";
import img2 from "../assets/images/release2.png";
import img3 from "../assets/images/release3.png";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

export default function PressRelease() {
  React.useEffect(() => {
    getAllPressReleases();
  }, []);
  const [data, setdata] = React.useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState();
  const [currentPage] = React.useState(1);
  const [showperPage, setshowperPage] = React.useState(3);
  const [disablebtn, setdisablebtn] = React.useState(false);

  const indexofLast = currentPage * showperPage;
  const indexofFirst = indexofLast - showperPage;
  const currentlyPages = data?.slice(indexofFirst, indexofLast);
  const asset_path = "upload/";

  const getAllPressReleases = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/pressReleases`)
      .then(function (response) {
        setdata(response?.data?.PressReleases);
        console.log(
          response?.data?.PressReleases,
          "response?.data?.PressReleases"
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const nextpage = () => {
    if (data?.length >= showperPage) {
      setshowperPage(3 + showperPage);
    } else {
      setdisablebtn(true);
    }
  };
  const modalFunciton = (row) => {
    setModalData(row);
    setModalShow(true)
  }
  // console.log(modalData,"press relese Modal Data")

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           {modalData?.Title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><span>{moment(modalData?.Date).format("MM-DD-YYYY")}</span></p>
          <p>
            <div dangerouslySetInnerHTML={{ __html: modalData?.Description }} />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function trimString(string, length) {
    return (string?.length > length ? 
           string.substring(0, length) + '...' :
           string);
  }
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <section id="pressreleses" className="pressRelease-banner">
        <div className="container">
          <div className="pressRelease-main row">
            <div>
              <div className="pressRelease-2nd">
                <h1
                  className="Montserrat-B greenColor"
                  style={{ marginLeft: "40px" }}
                >
                  Press Releases
                </h1>
              </div>
            </div>
            {currentlyPages?.map((row) => (
              <>
                <div className="col-lg-1"></div>
                <div className="col-lg-6">
                  <div
                    onClick={() => modalFunciton(row)}
                    className="greenleft-border pressRelease-inner"
                  >
                    <div className="pressRelease-cntant">
                      <p
                        className="Montserrat-R"
                        style={{ marginBottom: "3px", fontSize: "10pt" }}
                      >
                        <b>{moment(row?.Date).format("MM-DD-YYYY")}</b>
                      </p>
                      <h4 className="Montserrat-B grayColor">{row?.Title}</h4>
                      <div dangerouslySetInnerHTML={{ __html: trimString(row?.Description, 400) }} />
                    </div>
                    <div className="graybottmborder"></div>
                  </div>
                </div>
                <div className="col-lg-4">
                  {row?.AttachmentType == "image" && 
                    <img src={asset_path+row?.AttachmentLink} alt="" className="pressRelease-img imgbglearn" />
                  }
                  {row?.AttachmentType == "youtube" &&
                  <iframe
                  className="col-12"
                  height="auto"
                  src={`https://www.youtube.com/embed/${row?.AttachmentLink}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                ></iframe>
                
                  }
                </div>
                <div className="col-lg-2"></div>
              </>
            ))}
            <div className="col-lg-1"></div>
            <div className="col-lg-7">
              <div className="morepress">
                {disablebtn ? (
                  ""
                ) : (
                  <p
                    className="Montserrat-M lgreenColor pointershover"
                    onClick={() => nextpage()}
                  >
                    More Press Releases
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </section>
    </>
  );
}
