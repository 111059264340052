import React, { useEffect } from "react";
import greensolid from "../assets/images/Assetsolid.png";
import Aos from "aos";
import SecondBannerCompo from "../SecondBannerCompo"

export default function OurVision() {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  return (
    <section className="banner-ourVision">
      <div className="container">
        <div className="row greenSolid_Div">

          
        
  
          <div className="col-lg-12 col-md-12 ">
          <SecondBannerCompo
              mainText={
                <>
                  “An Institution which uses business to inspire people and take care of everyone’s health and well-being”
                </>
              }
              classNameBorder="  mutawallis_Green_Border"
              classNameMainDiv="  sadia_Main_Div"
            />
          </div>
        
        </div>
      </div>
    </section>
  );
}
