import React from 'react';
import './home.css';
import Man from '../assets/images/lp/Section-5-Our responsibility/Hakim Said Memorial Scholorship/001.jpg';
import Man2 from '../assets/images/lp/Section-5-Our responsibility/Hakim Said Memorial Scholorship/002.jpg';
import Man3 from '../assets/images/lp/Section-5-Our responsibility/Hakim Said Memorial Scholorship/003.jpg';
import green from '../assets/images/line.png';
import hamdard from '../assets/images/hamdard.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {useNavigate } from "react-router-dom";

export default function Banner1920() {
    const navigate = useNavigate();
    return (
        <section className="banner-1920" >
            <div className="container" data-aos="fade-up" >
                <div className="row">
                    <div className="col-lg-5 col-md-12" >
                        <div className="container">
                            <div className="row">
                            <div className="banner1920-heading-contant">
                                <div className="contant-heading">
                                    <h5 className="Montserrat-M" style={{color:"#E4E4E4"}}>OUR <b>RESPONSIBILITY</b></h5>
                                </div>
                                <div className="contant-1920">
                                    <h2 className="Montserrat-B" style={{color:"#fff"}} >An inspiring organization to focus on health and well-being</h2>
                                </div>
                                <div className="greenline-responsibility"></div>
                                <div className="contant-ptag">
                                    <p className="Poppins-R" style={{color:"#E4E4E4"}}>Adhering to the global standards of the community, Hamdard has devoted its services to operate with complete dedication and sustainability. Our products reflect the essence of promoting social welfare, education, and regulating health standards. As a local brand of Pakistan, we strongly believe in creating a safe and supported environment for its citizens.</p>
                                </div>
                                <div className="btn-div">
                                        <button type="button" onClick={() => navigate('our-responsibility')} className="Poppins-R custmbtn-1920 btn btn-light">READ MORE</button>
                                    </div>
                            </div> 
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-7 col-md-12">
                    <Carousel>
                        <div>
                            <img src={Man} />
                            <p className="legend">Legend 1</p>
                        </div>
                        <div>
                            <img src={Man2} />
                            <p className="legend">Legend 2</p>
                        </div>
                        <div>
                            <img src={Man3} />
                            <p className="legend">Legend 3</p>
                        </div>
                    </Carousel>
                    {/* <img className="img1" src={Man}  alt="image"/> */}
                    {/* <img className="img2" src={green}  alt="image"/> */}
                    </div>
                    <div className="bottom1-1920">
                        <div className="bottom-1920">
                            <h4 className="Montserrat-M">serving humanity since</h4>  <h1 className="BebasNeueRegular" style={{fontSize:"100px",marginLeft:"20px"}}>1920</h1>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
    )
}
