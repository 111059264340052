import React from 'react';
import Qaid from "../assets/images/About us/About Hamdard/hakim sb.jpg";
import hamdard from '../assets/images/Assettt.png'

export default function YearsBanner() {
    return (
        <section className="banner-100years">
        <div className="container">
            <div className="row">
                <div className="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="200">
                    <div className="container">
                        <div className="row">
                        <div className="banner100-heading-contant">
                            <div className="contant-ptag-yearsbanner">
                                <p className="Poppins-R mobile-font-h6 paddingR-50">
                                The policy of Hamdard Laboratories (Waqf) Pakistan is to deliver quality products to the market. Quality is the key priority in every aspect of our work. Our goal is to continually satisfy consumer expectations by providing high-quality products to improve quality of life. 
<br></br>
<br></br>


<h2 className="Montserrat-B" style={{fontSize:"12.61pt"}}>We are guided by Principle of Quality: </h2>
Our commitment is to give more to society than we take. This drives us to set and meet the attributes to comply with regulatory standards. Integrating methods of quality and integrity in our work environment ensures that our decisions are consumer-guided, delivering what is best for them.  
<br></br>
<br></br>

<h2 className="Montserrat-B" style={{fontSize:"12.61pt"}}>Quality creates value:</h2>

Quality should be built into the product and we aim to make quality an aspiring advantage for Hamdard to deliver safe, effective and HALAL products in time. It is also our aim to ensure our sustainability in business and be a better healthcare partner for Hamdard’s consumer family. 
<br></br>
<br></br>

                                </p>
                            </div>
                        </div> 
                        </div>
                    </div>
                </div>
                <div className="col-lg-1 col-md-12">
                </div>

                <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-delay="200">
                <div className="qaidimg-div">
                    <img className="qaidimg" src={Qaid}  alt="image"/>
                </div>
                </div>
                <div className="bottom1-100years">
                    <div className="bottom-100years">
                        <h4 className="Montserrat-M" style={{fontSize:"12.61pt"}}>Taking care of
                            generations for
                            more than</h4>  
                            <h1 className="BebasNeueRegular" style={{fontSize:"67.75pt",textAlign:"center"}}>100</h1>
                            <h4 className="Montserrat-M" style={{fontSize:"15.69pt"}}>Years</h4>
                    </div>
                </div>
                <div className="bottom-img-yearsbanner">
                            <img className="yearsbanner-hamdard" src={hamdard}  alt="image"/>
                        </div>
            </div>      
        </div>
    </section>
    )
}
