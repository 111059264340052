import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

export default function EventsSec() {
  const [data, setdata] = useState([]);
  useEffect(() => {
    getAllEvents();
  }, []);

  const asset_path = "upload/";

  const getAllEvents = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/events`)
      .then(function (response) {
        setdata(response?.data?.Events);
        console.log(response, "response?.data?.Publications");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <section className="events-sec-div">
      <div className="container">
        <div className="row">
          <div className="sec-events-div">
            <h3 className="Montserrat-B greenColor">
              An overwhelming response from the <br />
               public at Karachi Eat 2020, the biggest <br />
              food event of Pakistan.
            </h3>
          </div>
        </div>
      </div>
      <div className="container mt-5 pb-5">
        <div className="row">
          {data?.map((row) => (
            <>
              <div className="col-lg-4 col-md-12 mt-5">
                <div className="overflow-hidden sec-grid">
                  {row?.AttachmentType == "image" && 
                    <img src={asset_path+row?.AttachmentLink} alt="" className=" img-fluid" />
                  }
                  {row?.AttachmentType == "youtube" &&
                    <iframe
                    className="col-12"
                    height="250"
                    src={`https://www.youtube.com/embed/${row?.AttachmentLink}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                    controls="1"
                  ></iframe>
                  }
                </div>
              </div>
              <div className="col-lg-8 col-md-12 mt-5">
                <div className="grind-sec-headings">
                  <h2 className="Montserrat-B greenColor">
                    {row?.Title}
                  </h2>
                  <br />
                  <p className="Poppins-R grayColor">
                    {/* <div dangerouslySetInnerHTML={{ __html: row?.Description }} /> */}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
  );
}
