import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Man from '../assets/images/Asset13.png';
import img1 from '../assets/images/Our responsibility/02 - Hakim Said Memorial Scholorship/001.jpg';
import img2 from '../assets/images/Our responsibility/02 - Hakim Said Memorial Scholorship/002.jpg';
import img3 from '../assets/images/Our responsibility/02 - Hakim Said Memorial Scholorship/003.jpg';
import greensolid from '../assets/images/Assetsolid.png';
import search from '../assets/images/search.svg';
import { ReactComponent as Fb } from '../assets/images/fb.svg';
import { ReactComponent as Insta } from '../assets/images/insta.svg';
import { ReactComponent as Twitter } from '../assets/images/twitter.svg';
import { ReactComponent as Youtube } from '../assets/images/youtube.svg';
import '../Slider/slider.css';


export default function LeftimgRightheading() {
    return (
        <div className="rightimgleftheading">
            <div data-aos="fade-up">
                <section className="banner-sliderleftimg">
                    <div className="container bg-white">
                        <div className="row">
                            <div className="paddingsliderimg col-lg-6 col-md-12 align-self-xl-center ourRes_slider_center" >
                                <Carousel autoPlay={true} infiniteLoop={true}  showThumbs={false}>
                                    <img src={img1} className="" />
                                    <img src={img2} className="" />
                                    <img src={img3} className="" />
                                </Carousel>
                            </div>
                            <div className=" col-lg-6 col-md-12 p-5 ourRes_slider_center" >

                                <h2 className="Montserrat-B greenColor">Scholarships For Educational Purposes</h2>

                                <div className="sliderleftwala-ptag">
                                    <p className="Poppins-R">After the martyrdom of Hakim Mohammed Said, to continue, his love and efforts for education in Pakistan, Hamdard Foundation Pakistan from the beginning of the 21st Century started awarding Hakim Mohammed Said Memorial Scholarship to matriculation- passes and Intermediate-passed Meritorious students.
                                        <br></br>
                                        <br></br>
                                       
                                        Hamdard Foundation Pakistan started programming the Hakim Mohammed Said Memorial Scholarship in the year 2002-03. It was for the deserving matriculation- passes and Intermediate-passed students of Karachi, Lahore, Peshawar, and Quetta Boards.
                                        <br></br>
                                        <br></br>
                                        Now, Hamdard Foundation Pakistan is providing Hakim Mohammed Said Memorial Scholarship to deserving students of all 26 Govt. Educational Boards (matriculation and intermediate) of Pakistan.
                                    </p>



                                </div>
                            </div>
                            </div>
                            </div>
                        </section>
                    </div>
            </div>
            )
}
