import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Man from "../assets/images/slide4.jpg";
import Man2 from "../assets/images/lp/Banners/banner-02.webp";
import Man3 from "../assets/images/lp/Banners/banner-003.jpg";
import Man4 from "../assets/images/slider3.jpg";
import Man5 from "../assets/images/lp/Banners/banner-0004.jpg";
import greensolid from "../assets/images/Assetsolid.png";
import search from "../assets/images/search.svg";
// import { ReactComponent as Fb } from "../assets/images/fb.svg";
// import { ReactComponent as Insta } from "../assets/images/insta.svg";
// import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
// import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import Socialicons from '../socialIcons';
import "../Slider/slider.css";
import Aos from "aos";

export default function Slider() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    // Aos.init({ duration: 1500 });
  }, []);


  const slidersContent = [
    {
      image: Man5,
      legend: 'Legend 1',
      headings: <div className="h1-headings sm_banner_h1">
        <h1 className="Montserrat-B h1font">Inspiring with</h1>
        <h1 className="Montserrat-B h1font">thought Knowledge</h1>
        <h1 className="Montserrat-B h1font">& Experience</h1>
      </div>,
    },
    {
      image: Man,
      legend: 'Legend 1',
      headings: <div className="h1-headings sm_banner_h1">
        <h1 className="Montserrat-B h1font">Inspiring with thought</h1>
        <h1 className="Montserrat-B h1font">Knowledge & Experience</h1>
      </div>,
    },
    {
      image: Man2,
      legend: 'Legend 2',
      headings: <div className="h1-headings sm_banner_h1">
        <h1 className="Montserrat-B h1font">Serving you<br></br> nature's goodness<br></br> for over 100 years</h1>
      </div>,
    },
    {
      image: Man3,
      legend: 'Legend 3',
      headings: <div className="h1-headings sm_banner_h1">
        <h1 className="Montserrat-B h1font">Enabling people<br></br> to live a healthy<br></br> rich life.</h1>
      </div>,
    },

    {
      image: Man4,
      legend: 'Legend 3',
      headings: <div className="h1-headings sm_banner_h1">
        <h1 className="Montserrat-B h1font">Enabling people<br></br> to live a healthy<br></br> rich life.</h1>
      </div>,
    },
  ]




  return (
    <div data-aos="fade">
      {/* <div className="search-div">
        <img src={search} className="searchicon" alt="" />
      </div> */}
      {/* <div className="slider-social-icons"> */}
      {/* <div className="slider-social-icons">
        <a href="#">
          <Fb className="fbicon" />
        </a>
        <a href="#">
          <Twitter className="twittericon" />
        </a>
        <a href="#">
          <Insta className="instaicon" />
        </a>
        <a href="#">
          <Youtube className="yticon" />
        </a>
      </div> */}
      <Socialicons  className="check" value={"green"} />
      <Carousel autoPlay={true} infiniteLoop={true} className="slider-home">

        {slidersContent.map((slide) => <div style={{display:'flex',alignItems:'center'}}>
          <div style={{width:"100%"}}><img 
          // height='auto'
            
           src={slide.image} className="main-slider" />
            <p className="legend">{slide.legend}</p></div>

          <div className="textdiv">
            <img
              src={greensolid}
              className="slidergreen"
              alt=""
              loading="lazy"
            />
            <p className="Montserrat-M ptags-slider grayColor sm_banner_content">
              HAMDARD <b>PAKISTAN</b>
            </p>
            {slide.headings}
          </div>
        </div>)}




        {/* <div>
          <img style={{ width: '100%', height: 'auto' }} src={Man5} className="main-slider" />
          <p className="legend">Legend 1</p>
          <div className="textdiv">
            <img
              src={greensolid}
              className="slidergreen"
              alt=""
              loading="lazy"
            />
            <p className="Montserrat-M ptags-slider grayColor">
              HAMDARD <b>PAKISTAN</b>
            </p>
            <div className="h1-headings">
              <h1 className="Montserrat-B h1font">Inspiring with</h1>
              <h1 className="Montserrat-B h1font">thought Knowledge</h1>
              <h1 className="Montserrat-B h1font">& Experience</h1>
            </div>
          </div>
        </div>



        <div>
          <img src={Man} className="main-slider" />
          <p className="legend">Legend 1</p>
          <div className="textdiv">
            <img
              src={greensolid}
              className="slidergreen"
              alt=""
              loading="lazy"
            />
            <p className="Montserrat-M ptags-slider grayColor">
              HAMDARD <b>PAKISTAN</b>
            </p>
            <div className="h1-headings">
              <h1 className="Montserrat-B h1font">Inspiring with thought</h1>
              <h1 className="Montserrat-B h1font">Knowledge & Experience</h1>
            </div>
          </div>
        </div>




        <div>
          <img src={Man2} className="main-slider" />
          <p className="legend">Legend 2</p>
          <div className="textdiv">
            <img
              src={greensolid}
              className="slidergreen"
              alt=""
              loading="lazy"
            />
            <p className="ptags-slider">
              HAMDARD <b>PAKISTAN</b>
            </p>
            <div className="h1-headings">
              <h1 className="Montserrat-B h1font">Serving you<br></br> nature's goodness<br></br> for over 100 years</h1>
            </div>
          </div>
        </div>





        <div>
          <img src={Man3} className="main-slider" />
          <p className="legend">Legend 3</p>
          <div className="textdiv">
            <img
              src={greensolid}
              className="slidergreen"
              alt=""
              loading="lazy"
            />
            <p className="ptags-slider">
              HAMDARD <b>PAKISTAN</b>
            </p>
            <div className="h1-headings">
              <h1 className="Montserrat-B h1font">Enabling people<br></br> to live a healthy<br></br> rich life.</h1>
            </div>
          </div>
        </div>



        
        <div>
          <img src={Man4} className="main-slider" />
          <p className="legend">Legend 3</p>
          <div className="textdiv">
            <img
              src={greensolid}
              className="slidergreen"
              alt=""
              loading="lazy"
            />
            <p className="ptags-slider">
              HAMDARD <b>PAKISTAN</b>
            </p>
            <div className="h1-headings">
              <h1 className="Montserrat-B h1font">Enabling people<br></br> to live a healthy<br></br> rich life.</h1>
            </div>
          </div>
        </div> */}
      </Carousel>
    </div>
  );
}
