import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
// import { DateRange } from "react-date-range";
// import { Button } from "react-bootstrap";
import './multiselect.css'
import axios from 'axios'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import  moment  from 'moment'

const options = [
  { value: 'Aqwal-e-Said', label: 'Aqwal-e-Said' },
  { value: 'Rooh Afza Recipes', label: 'Rooh Afza Recipes' },
  { value: 'Naunehal', label: 'Hamdard Naunehal' },
  { value: 'Khabarnama', label: 'Hamdard Khabarnama' }
]

const orderopt = [
  { value: 'ASC', label: 'Ascending Order' },
  { value: 'DSC', label: 'Descending order' }
]

export default function Multiselect (props) {
  const { multiSelectData, setMultiselectData } = props

  const [selected, setSelected] = useState('')
  const [order, setOrder] = useState([])
  const [show, setShow] = useState(false)
  const [value, onChange] = useState('')
  const [publicationValues, setPublicationValues] = useState([])

  useEffect(() => {
    multiselect()
  }, [])

  let fromDate = ''
  let tillDate = ''
  let type = ''

  // console.log(type)
  const multiselect = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/getAllPublicationTypes`)
      .then(function (response) {
        setPublicationValues(response.data.PublicationTypes)
      })
      .catch(function (error) {})
  }

  const handelOne = e => {
    type = e.target.value
    setMultiselectData({
      ...multiSelectData,
      // fromDate,
      // tillDate,
      type
    })
    // multiselect();
    setSelected(e.target.value)
    // handelThree()
  }

  const handelTwo = e => {
    setOrder(e.value)
    setMultiselectData({
      ...multiSelectData,
      order: e.value
    })
  }

  let a = ''
  let b = ''
  const handelThree = e => {
    // let a = `${e ? e[0] : null}`
    // let b = `${e ? e[1] : null}`
    console.log(e);

    if(e && e[0] && e[1]){
    a = e[0]
    b = e[1]

      fromDate =
      moment(a).format('YYYY-MM-DD') + "T00:00:00.000Z"
      tillDate =
      moment(b).format('YYYY-MM-DD')  + "T00:00:00.000Z"
      console.log(fromDate,'=============',tillDate)

      onChange([fromDate,tillDate]);
      setMultiselectData({
        ...multiSelectData,
       fromDate,
       tillDate,
       // type:"hamdard khabarnama"
 
      })
    }
    // // fromDate =  a
    // // tillDate =  b

    

    // let localTime = val01 + "T00:00:00.000Z";
    // let localTime2 = val02 + "T00:00:00.000Z";

    // fromDate = localTime;
    // tillDate = localTime2;
  }


  // const clearFilterHandle = (e) =>{
  //   // e.preventDefault()
  //   e.target.reset()

  // }



  return (
    <section className='multiselect-div'>
      <div className='container'>
        <div className='row'>
          <div className='clear-padding col-md-12 col-lg-12'>
            <div className='publications-multiselect'>
              <h1 className='Montserrat-B greenColor'>Publications</h1>
              <form 
              // onReset={(e)=>clearFilterHandle(e)}
              >
                <div
                  class='row'
                  style={{
                    position: 'relative',
                    margin: '0 auto',
                    display: 'flex ',
                    justifyContent: 'center',
                    alignItems:'center'
                  }}
                >
                  <div class='col-lg-3 col-md-6 pt-3'>
                    <select
                      name='drinks'
                      className='date_btn'
                      onChange={e => handelOne(e)}
                    >
                      <option value='' disabled selected hidden>
                        *Publication types
                      </option>
                      {publicationValues.map((e, i) => {
                        return <option>{e.Type}</option>
                      })}
                    </select>
                  </div>

                  <div class='col-lg-3 col-md-6 pt-3'>
                    <Select
                      className='produciton'
                      // value={order}
                      onChange={e => handelTwo(e)}
                      options={orderopt}
                      placeholder='Order'
                    />
                  </div>

                  <div class='col-lg-3 col-md-6 pt-3'>
                    {/* <Button className="date_btn" onClick={() => setShow(!show)}>
                      {show ? '' : "Date Select"}
                    </Button>
                    {show ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                        }}
                      >
                        <DateRange
                          editableDateInputs={true}
                          onChange={(item) => handelThree([item.selection])}
                          moveRangeOnFirstSelection={false}
                          ranges={state}
                          moveRangeOnFirstSelection={false}
                        />
                      </div>
                    ) : null} */}
                    <div className='date_btn pt-3'>
                      <DateRangePicker
                        className='check'
                        closeCalendar={true}
                        onChange={e => handelThree(e)}
                        value={value}
                      />
                    </div>
                  </div>
                  {/* <div class='col-lg-2 col-md-2 pt-3'><button type='reset'  style={{borderStyle:'none'}}>Clear Filter</button></div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
