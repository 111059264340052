import React from "react";
import "./home.css";
import Man from "../assets/images/Asset4.png";
import green from "../assets/images/line.png";
import hamdard from "../assets/images/hamdard.png";
import {useNavigate } from "react-router-dom";

export default function BannerThree() {
  const navigate = useNavigate();

  return (
    <section className="banner-three">
      <div className="container">
        <div className="row">
          <div
            className="col-md-12 col-lg-6"
            data-aos="flip-left"
            data-aos-once="true"
          >
            <div className="bannerthree-img-testing"></div>
            <img className="b3img1" src={Man} alt="image" />
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="container">
              <div className="row">
                <div className="mt-3">
                  <div
                    className="bannerthree-heading-contant"
                    data-aos="flip-right"
                    data-aos-once="true"
                  >
                    <div className="contant-heading">
                      <h5 className="Montserrat-M grayColor">
                        WHAT WE OFFER - <b>SERVICES</b>
                      </h5>
                    </div>
                    <div className="contant3">
                      <h2 className="Montserrat-B greenColor">
                      Unani practices for effective health care services
                      </h2>
                    </div>
                    <div className="greenline-bannertwo"></div>
                    <div className="contant-ptag">
                      <p className="Poppins-R grayColor">
                      Hamdard Matabs are healthcare clinics that have been providing unani healthcare services across the country through qualified consultancy since June 26, 1948. The motive behind introducing this wide network of medical clinics was to ensure health service for all, without any consultation or diagnosis fee. The patients are only charged for the medicines purchased.
                      </p>
                      <p className="Poppins-R grayColor">
                      Shaheed Hakim Mohammed Said started Hamdard Free Mobile Dispensaries in 1963, to provide free-on-the spot treatment needy patients, where the physician examines the patient and distributes free medicines in the disadvantaged areas of the major cities of the country. Hamdard Foundation Pakistan gradually expanded and enhanced this philanthropic activity and continues to increase its fleet of free mobile dispensaries across the country. At present, Hamdard Free Mobile Dispensaries are treating more than 500,000 patients every year, many of whom cannot reach hospitals.
                      </p>
                    </div>
                    <div className="btn-div">
                      <button
                        type="button"
                        className="Poppins-R custmbtn-three"
                        onClick={() =>  navigate("services")}
                      >
                        READ MORE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
