import React from "react";
import SecondBannerCompo from "../SecondBannerCompo";

export default function SecendBanner() {
  return (
    <section className="banner-ourVisions banner-ourresponsibility">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-lg-12">
            <SecondBannerCompo
              mainText={
                <>
                  “The possibilities are limitless in the future but we need to
                  hold hands with the rest of the world to sustain our faith and
                  endeavour to share in the work for betterment, human welfare
                  and universal peace. We need you to help us in our journey for
                  humanity.”
                </>
              }
              classNameBorder="  responsibility_Green_Border"
              classNameMainDiv="  responsibility_Main_Div"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
