import React, { useEffect, useState } from "react";
import "./footer.css";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import Aos from "aos";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

export default function FooterTwo() {
  const navigate = useNavigate();
  // const [formdata, setFormData] = useState();
  const [error, setError] = useState("");
  const [dataSucess, setDataSucess] = useState("");
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);

  const [contactFormData, setContactFormData] = useState({})

  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  const handelClick = (e) => {
    e.preventDefault()
    e.target.reset()
    setDisable(true)
    setLoader(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/public/contactUs`,
      data: contactFormData,
    })
      .then((res) => {
          setDataSucess(res?.data?.message[0]);
          setLoader(false)
          e.target.reset()
          setDisable(false)
        
      })
      .catch((err) => {
          setError(err?.response?.data?.message[0]);
          setLoader(false);
          setDisable(false)
      });
  };

  const onChangeHandle = (e) =>{
    const name = e.target.name
    const val  = e.target.value

    setError('')
    setDataSucess('')


    setContactFormData({...contactFormData,[name]:val})
  }

  return (
    <section className="section-footer-two pt-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12 colorbgs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="inner-footer-div-two">
                  <div className="footer-contant-heading">
                    <h5 className="Montserrat-M greenscolor">CONTACT US</h5>
                  </div>
                  <div className="footer-contant">
                    <h2 className="Montserrat-B greenscolor">Get in touch</h2>
                  </div>
                  <div className="footer-contant-ptag">
                    <p className="Poppins-R lightgray greenscolor">
                      Let us be a part of your discovery. Leave a message for
                      any queries or concerns.
                    </p>
                  </div>
                  <form onSubmit={(e) => handelClick(e)}>
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 sm_Margin">
                        <input
                        required
                          type="text"
                          class="form-control check"
                          placeholder="Full Name"
                          name='FullName'
                          onChange={(e) => onChangeHandle(e)}
                        />
                      </div>
                      <div class="col-xs-12 col-sm-6 sm_Margin lg_margin">
                        <input
                        required
                          type="email"
                          class="form-control check"
                          placeholder="Email"
                          name='Email'
                          onChange={(e) => onChangeHandle(e)}
                        />
                      </div>
                      <div class="col-xs-12 col-sm-6 sm_Margin lg_margin">
                        <input
                        required
                          type="number"
                          class="form-control check"
                          placeholder="Contact Number"
                          name='ContactNumber'
                          onChange={(e) => onChangeHandle(e)}
                        />
                      </div>
                    </div>
                    <div class="lg_margin sm_Margin form-group">
                      <textarea
                      required
                        class="form-control check"
                        id="exampleFormControlTextarea1"
                        placeholder="Message"
                        rows="8"
                        name='Message'
                        onChange={(e) => onChangeHandle(e)}
                      ></textarea>

                      <div style={{ color: "red", marginTop: "1rem" }}>
                        {dataSucess ? (
                          <h5 style={{ color: "green" }}>{dataSucess}</h5>
                        ) : (
                          <h5>{error}</h5>
                        )}
                      </div>
                    </div>
                    <div className="footer-bottom-btn">
                      <button
                        type="submit"
                        // onClick={() => handelClick()}
                        disabled={dataSucess ? disable : false}
                        className="custmbtn-footer-two"
                      >
                        {loader
                          ? "SENDING...."
                          : dataSucess
                          ? "MESSAGE SENT"
                          : "SEND MESSAGE"}
                      </button>
                    </div>
                  </form>
                  <div className="bottom-menu-li">
                    <div className="container footer_use_links">
                      <div className="row">
                        <div className="col-lg-6 footerBottomTwo">
                          <div style={{ marginTop: "40px" }}>
                            <div className="menu-footer-li">
                              <ul className="footer-li-two">
                                <li>
                                  <Link
                                    to="about-hamdard"
                                    // onClick={() => navigate("about-hamdard")}
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    About Hamdard
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="services"
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    What We Offer
                                  </Link>
                                </li>
                                <li>
                                  <a
                                    href="https://www.hamdardfoundation.org/"
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    Hamdard Foundation
                                  </a>
                                </li>
                                <li>
                                  <Link
                                    to="our-responsibility"
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    Our Responsibility
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 footerBottomTwo">
                          <div  className="menu_lg_mt_Top">
                            <div className="menu-footer-li">
                              <ul className="footer-li-two">
                                <li>
                                  <Link
                                    to="publication&Event"
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    Publication & Events
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="international-busniess"
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    Global Presence
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="opportunities"
                                    className="Poppins-R footer-alink-two footerfont"
                                  >
                                    Work with us{" "}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 colorbgstwo" data-aos="fade-up">
            <div className="container footer_use_links">
              <div className="row">
                {/* <div className=""> */}
                <div className="footer-contant">
                  <h2 className="Montserrat-B greenscolor">Get in touch</h2>
                </div>
                <div className="footer-contant-ptag">
                  <p className="Poppins-R lightgray greenscolor">
                    <b>
                      Connect digitally with Hamdard and create a sustainable
                      future. <br />
                    </b>
                  </p>
                </div>
                <div className="slider-social-icons-footer">
                  <div className="rounded-divs-two">
                    <a
                      href="https://www.facebook.com/Hamdardpkofficial/"
                      target={"_blank"}
                    >
                      <Fb className="fbicon-two" />
                    </a>
                  </div>
                  <div className="rounded-divs">
                    <a href="https://twitter.com/hamdard_pk" target={"_blank"}>
                      <Twitter className="twittericon-two" />
                    </a>
                  </div>
                  <div hidden className="rounded-divs">
                    <a href="https://www.instagram.com/hamdardpakistan/?hl=en">
                      <Insta className="instaicon-two" />
                    </a>
                  </div>
                  <div className="rounded-divs">
                    <a
                      href="https://www.youtube.com/c/HamdardPakistan/videos"
                      target={"_blank"}
                    >
                      <Youtube className="yticon-two" />
                    </a>
                  </div>
                </div>
                <div className="footer-contant">
                  <h2 className="Montserrat-B greenscolor">Contact us</h2>
                </div>
                <div className="inner-footer-two">
                  <div className="footer-contact-two">
                    <div className="p-3 pb-5 pt-5 footerPadding">
                      <h6 className="Poppins-R lightgray">
                        <a href="tel:+922138244000">Tel: (+92 21) 38244000 </a>{" "}
                        <br></br>
                        <a href="tel:+922138241555">Fax: (+92 21) 38241555</a>
                      </h6>
                      <br></br>
                      <h6 className="Poppins-R lightgray">
                        <a className="text-break" href="mailto:headoffice@hamdard.com.pk">
                          headoffice@hamdard.com.pk
                        </a>
                      </h6>
                      <br></br>
                      <h6 className="Poppins-R lightgray">
                        Hamdard Laboratories (Waqf) Pakistan.
                        <br />
                        172/2 PECHS Main Tariq Road, Bahria Town Tower, Floor
                        No. 15, Karachi.
                      </h6>
                    </div>
                  </div>
                  <div >
                    <div className="address-footer">
                      <p
                        className="Poppins-R footerfont"
                        style={{ color: "#fff" }}
                      >
                        ©2021, Hamdard Laboratories (Waqf) Pakistan <br />
                        Made with passion by The Craftsmen
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1"></div>
                {/* </div> */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
