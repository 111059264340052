import React, { useEffect } from "react";
import "../AboutHamdard.css";
import "./directors.css";
import { ReactComponent as Fb } from "../../assets/images/fb.svg";
import { ReactComponent as Insta } from "../../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/images/youtube.svg";

import man from "../../assets/images/About us/Board of Directors/03-A.jpg";
import man2 from "../../assets/images/About us/Board of Directors/03-B.jpg";
import directorslider from "./directorslider";

import Aos from "aos";
import bannerimg from "../../assets/images/About us/Board of Directors/banner.jpg";
import Directorsslider from "./directorslider";
import Socialicons from '../../socialIcons';
import SecondBannerCompo from "../../SecondBannerCompo";

export default function Fatema() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
     <section className="page-leadership">
      <section>
        <div className="banner-div">
          <div className="empty-section-res"></div>
          <Socialicons value={"white"} />
          <div className="img-div-whatweoffer">
            <img
              src={bannerimg}
              className="bannerimg-whatwe-offer"
              alt="bannerimg"
              loading="lazy"
            />
          </div>
        </div>
      </section>
      <section className="whoweare">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
            <SecondBannerCompo
                  mainText={
                    <>
                      
                      Our leadership team drives our <br></br> company, guides
                      our strategy and<br></br> leads our people.
                    </>
                    
                  }
                  classNameBorder="  mutawallis_Green_Border"
                  classNameMainDiv="  sadia_Main_Div"
                />
            </div>
          </div>
        </div>
      </section>

      <section className="whoweare p-5">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <img src={man} className="innerwhoweare-img col-8 offset-1" />
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-5 col-md-12">
              <div className="whoweare-contant">
                <h2 className="Montserrat-B greenColor">
                  Ms Fatema Munir Ahmed
                </h2>

                <h4 className="Montserrat greenColor">
                  Managing Director & CEO
                </h4>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>

      <section className="bg-abouthamdard banner-one onemore mt-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6" data-aos="flip-left">
              <div className="about-left-section-ib">
                <div className="contant">
                  <h2 className="Montserrat-B greenColor">
                    From a small Matab to a Worldwide Leader in Herbal
                    Medicines, Hamdard continues to write history.
                  </h2>
                </div>
                <div className="contant-ptag-ib">
                  <p className="Poppins-R grayColor">
                  Hamdard Pakistan's journey is truly and odyssey of efforts and successes. But from the very start the helmsman, our founder, Hakim Mohammed Said -  had one soul harbour he was heading for: Serving humanity and the betterment of Pakistan. He said:  ‘Love Pakistan, Build Pakistan’ and worked untiringly for this.<br></br><br></br>
The aim of Hamdard was to become the standard of excellence in a marriage of old and new, tradition and modern science. And we believe we are on the way to achieving this but with shifting paradigms and Rapid advancement of Technology, global Business dynamics have also changed. Turning towards this inevitable call of change, we have taken the initiative to rebrand. We wish not just to emphasize our traditional strength, values, reputation, and credibility in the world, but also to reflect our resolve for to embark into the future with new-found vigour.

                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6" data-aos="flip-right">
              <div className="about-img-bg">
                <img className="imgib" src={man2} alt="image" />
                <div className="about-img-testing"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
      <Directorsslider />
    </>
  );
}
