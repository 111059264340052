import React,{useEffect} from 'react';
// import Header from '../Navbar/header';
import Banner from './banner';
import '../WhatWeOffer/main.css';
import Service from './service';
import BannerformHome from '../Home/bannerfour';
import ServiceMobile from './serviceMobile';
import Footer from '../Footer/footer';


export default function Main() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='page-services'>
            <Banner/>
            <Service />
            <BannerformHome/>
            <ServiceMobile/>
            {/* <Footer/> */}
        </div>
    )
}
