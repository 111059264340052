import React from "react";
import bannerimg from "../assets/images/About us/About Hamdard/bannerjpg.jpg";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import Socialicons from '../socialIcons';

export default function Banner() {
  return (
    <div className="banner-div">
      <div className="empty-section-res"></div>

      <div className="sliderIcon_abouthamdard">

      <Socialicons value={"white"} />
      <div className="img-div-whatweoffer">
        <img
          src={bannerimg}
          className="bannerimg-whatwe-offer"
          alt="bannerimg"
          // loading="lazy"
          />
      </div>
          </div>
    </div>
  );
}
