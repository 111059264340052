import React, { useEffect } from "react";
import greensolid from "../components/assets/images/Assetsolid.png";
import Aos from "aos";

export default function SecondBannerCompo({mainText , classNameBorder , classNameMainDiv}) {
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  return (
    <section className="banner-ourVision">
      <div className="container">
        <div className="greenSolid_Div">

          
          <img
              src={greensolid}
            //   className={classNameBorder  ? classNameBorder : "ourvision_greenBorder" ?  "ourvision_greenBorder"}
              className={`ourvision_greenBorder  ${classNameBorder  ? classNameBorder : ""}`}
              alt="borderImage"
              loading="lazy"
            />
  
          <div className="col-lg-12 col-md-12 ">
            <div className="banner-abouthamdard-2ndgreendiv">
                <div className=  {`ourVision_textDiv ${  classNameMainDiv ? classNameMainDiv : "ourVision_textDiv "}`} >
                <div className="abouthmadard-ourvision-contact" data-aos="flip-down">
                  <h5 className="Montserrat-M grayColor mobile-font-h6">
                    OUR <b>VISION</b>
                  </h5>
                  <h2 className="Montserrat-B mobile-font-h2">
                   {mainText}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
}
