import React from 'react';
import bannerimg from "../assets/images/Our responsibility/SR-banner.jpg";
import {ReactComponent as Fb} from '../assets/images/fb.svg';
import {ReactComponent as Insta} from '../assets/images/insta.svg';
import {ReactComponent as Twitter} from '../assets/images/twitter.svg';
import {ReactComponent as Youtube} from '../assets/images/youtube.svg';
import Socialicons from '../socialIcons';

export default function ResponsibilityBanner() {
    return (
        <div className="banner-div" data-aos="fade">
                <div className="empty-section-res">
                </div>
          <Socialicons value={"white"} customClass={"responsibilty-banner-social-icons"} />
            <div className="img-div-whatweoffer">
                <img src={bannerimg} className="bannerimg-whatwe-offer" alt="bannerimg"
                //  loading="lazy"
                />
            </div>
        </div>
    )
}
