import React,{useEffect} from 'react'
import Banner from './banner';
import BeYouFeel from './beYouFeel';
import './workWithusMain.css';

export default function WorkWithUsMain() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='work-With-Us'>
        <BeYouFeel/>
        </div>
    )
}
