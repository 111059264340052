import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Nav, NavItem, NavLink, TabContent, Row, Col, TabPane } from 'reactstrap';

const Cities = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [citiesData, setCitiesData] = useState([]);
    const [matabsData, setMatabsData] = useState([]);

    useEffect(() => {
        const fetchCitiesData = async () => {
            try {
                const timestamp = new Date().getTime(); // Unique timestamp
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}public/cities?_=${timestamp}`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                    }
                });
                setCitiesData(response.data.data);
            } catch (error) {
                console.error('Error fetching cities data:', error);
            }
        };

        const fetchMatabsData = async () => {
            try {
                const timestamp = new Date().getTime(); // Unique timestamp
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}public/hamdardMatabs?_=${timestamp}`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                    }
                });
                setMatabsData(response.data.data);
            } catch (error) {
                console.error('Error fetching hamdardMatabs data:', error);
            }
        };

        fetchCitiesData();
        fetchMatabsData();
    }, []);

    const getMatabsForCity = (cityId) => {
        return matabsData.filter(matab => matab.city_id === cityId);
    };

    return (
        <section className="" data-aos="fade-up" data-aos-delay="500">
            <div className="container">
                <h5 className="Montserrat-M grayColor mb-4 citits-h5">Find your nearest<b> Hamdard Matab</b> </h5>
            </div>
            <div>
                <Nav tabs>
                    {citiesData.map((city) => {
                        let id = JSON.stringify(city.id);
                        return (
                            <NavItem key={city.id} onClick={() => setActiveTab(id)}>
                                <NavLink className={activeTab === id ? 'active' : 'nonactive'}>
                                    {city.city}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>

                <TabContent activeTab={activeTab}>
                    {citiesData.map((city) => {
                        let id = JSON.stringify(city.id);
                        let matabs = getMatabsForCity(city.id);
                        return (
                            <TabPane key={city.id} tabId={id}>
                                <Row>
                                    {matabs.map((matab, index) => (
                                        <Col key={index} md="3" className="border-right">
                                            <p className="Montserrat-M darkgray"><b>{matab.title}</b></p>
                                            <p className="Montserrat-M darkgray mb-1 mt-3"><b>Contact Person:</b> {matab.contact_person}</p>
                                            <p className="Montserrat-M darkgray"><b>Address:</b> {matab.address}</p>
                                            <p className="Montserrat-M darkgray lineheightcities"><b>Contact:</b> {matab.contact_number}</p>
                                        </Col>
                                    ))}
                                </Row>
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </section>
    );
}

export default Cities;
