import React,{useEffect} from 'react';
// import Header from '../Navbar/header';
import Footer from '../Footer/footer';
import Slider from '../Slider/slider';
import BannerOne from './bannerAboutHamdard';
import BannerTwo from './bannerTwo';
import BannerThree from './bannerThree';
import Bannerfour from './bannerfour';
import BannerWorkWithUs from './bannerWorkWithUs';
import BannerGlobal from './bannerGlobal';
import Banner1920 from './banner1920';
import BannerFoundation from './bannerFoundation';
import BannerEvents from './bannerEvents';

export default function Home() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='home-page'>
            <Slider/>
            <BannerOne/>
            <BannerTwo/>
            <BannerThree />
            <Bannerfour/>
            <BannerFoundation />
            <Banner1920/>
            {/* <BannerEvents/> */}
            <BannerGlobal/>
            <BannerWorkWithUs/>
        </div>
    )
}
