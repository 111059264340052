import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ReactComponent as Fb } from "./assets/images/fb.svg";
import { ReactComponent as Insta } from "./assets/images/insta.svg";
import { ReactComponent as Twitter } from "./assets/images/twitter.svg";
import { ReactComponent as Youtube } from "./assets/images/youtube.svg";
import "./Slider/slider.css";

export default function SocialIcons(props) {
  let dvclr = props?.value !== "white"?"social-before-color-green":"social-before-color-white";
  const iconclr = props?.value !== "white"?"social-icon-color-green":"social-icon-color-white";
  if(props?.customClass !== undefined){
    dvclr += " "+props?.customClass;
  }
  return (
    <div>
      <div className={'slider-social-icons '+dvclr}>
        <a target="_blank" href="https://www.facebook.com/Hamdardpkofficial/">
          <Fb className={'fbicon '+iconclr} />
        </a>
        <a target="_blank" href="https://twitter.com/hamdard_pk">
          <Twitter className={'twittericon '+iconclr} />
        </a>
        <a target="_blank" href="https://www.instagram.com/hamdardpakistan/?hl=en">
          <Insta className={'instaicon '+iconclr} />
        </a>
        <a target="_blank" href="https://www.youtube.com/c/HamdardPakistan/videos">
          <Youtube className={'yticon '+iconclr} />
        </a>
      </div>
    </div>
  );
}








