import React from 'react';
import './home.css';
import before from '../assets/images/Assett1.svg';
import after from '../assets/images/Assett7.svg';

export default function Bannerfour() {
    return (
        <section className="bgimg-greenbanner banner-four">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="container">
                            <div className="row">
                                <div className="bannerfour-contant-heading">
                                    <div className="bannerfour-inner-contant">
                                    <div className="bannerfoure-before-div" data-aos="flip-up">
                                        <img className="bannerfoure-before" src={before}  alt="before"/>
                                    </div>
                                            <div className="contant4">
                                                <h2 className="Montserrat-B" style={{color:"#fff"}}>We have each come into this world<br></br>
                                                for a specific reason to live, love and serve <br></br>
                                                Allah through services of His creatures</h2>

                                   
                                            </div>
                                           <div className="contant-ptag2">
                                                    <p className="Montserrat-B" style={{color:"#fff"}}>SHAHEED HAKIM MOHAMMED SAID</p>
                                                </div>
                                    <div className="bannerfoure-after-div" data-aos="flip-down">
                                        <img className="bannerfoure-after" src={after}  alt="before"/>
                                    </div>
                                    </div>
                                            
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
    )
}
