import React from "react";
import "./home.css";
import img4 from "../assets/images/foundation2.jpg";
import img3 from "../assets/images/foundation3.jpg";
import img2 from "../assets/images/foundation1.jpg";
import img1 from "../assets/images/foundation4.jpg";
import foundation from "../assets/images/foundation.png";
import plus from "../assets/images/pluswhite.svg";
import { Button, Modal } from "react-bootstrap";



export default function BannerFoundation() {
  const matab1 = {
    'Title':'Madinat-Ul-Hikmat ',
    'Description':'<p>The establishment of Madinat-Ul-Hikmat in 1983 in the outskirts of Metropolis Karachi is one of the greatest services to the nation by the founder of Hamdard Pakistan, Shaheed Hakim Mohammed Said. One-of-its-kind city of knowledge and wisdom was established over a picturesque landscape with historical significance. As per renowned historians and archeologists, muslim forces under the leadership of Muhammed bin Qasim were stationed here on their way to Debal.The Nature, at Hamdard, manifests itself explicitly. Within its boundaries, purpose-built architectural marvels of different faculties and departments merge well into the planned landscape setting spread over an area of 350 acres. It is in the context of this natural setting and historical site that Shaheed Hakim Mohammed Said envisioned and established Hamdard University (1985) – now one of the biggest seats of higher learning in the Private Sector.</p>'
    
  };
  const matab2 = {
    'Title':'Bait-Ul-Hikmat',
    'Description':'<p>Bait-Ul-Hikmat library, the first major and most important institution of Madinat-Ul-Hikmat was established on 11 December 1989. The library was named Bait-Ul-Hikmat to commemorate the first one established in Baghdad by Caliph Abu Jafar-Ul-Mansoor. Later it was developed by Caliph Haroon Rashid and Caliph Mamoon Rasheed during the 14th century. The present Bait-Ul-Hikmat symbolizes a wooded transfusion to the Muslim’s traditional love for learning and scholarship.The Bait-Ul-Hikmat possesses all the modern library equipment, books, non-book materials as well as technical and experienced hands to furnish everything needed by the seekers of knowledge. Bait-Ul-Hikmat library today is one of the biggest privately managed libraries in Asia</p>'
  };
  const matab3 = {
    'Title':'Faculty of Eastern Medicine',
    'Description':'<p>Shaheed Hakim Mohammed Said was one of the greatest exponents of Eastern Medicine (Tibb-e-Unani). The establishment of an Eastern Medicine University which could enliven the intellectual traditions of the Golden Era of Muslim Civilization had always been his most cherished dream. Faculty of Eastern Medicine is the pioneering degree awarding institution of Hamdard University (Jamia Tibbia Sharqi / Hamdard Tibbi College / since 1958) that took shape as a constituent institution (Hamdard Al-Majeed College of Eastern Medicine upgraded in the year 1995). Keeping in view the above mentioned, Hakim Mohammed Said’s vision for the Faculty of Eastern Medicine is compatible with Hamdard University to develop it as an excellent center for higher education, is now at the verge to grow into a citadel of knowledge for the new millennium.</p>'
  };
  const matab4 = {
    'Title':'Faculty of Social Sciences & Humanities',
    'Description':'<p>Hamdard Institute of Education and Social Sciences Institute (HIESS) was established in 1992. It has a unique privilege to be the first constituent institute of Hamdard University. The priority given to this institute was a clear manifestation of both Shaheed Hakim Mohammed Said`s realization of the importance of teacher education in the development of future generations and his disappointment and discontentment with the traditional methodologies of the then existing teacher training institutions. The basic statement, which later became the motto of the institute, was “the production of the unconventional, capable, dynamic, creative, democratic and innovative teacher”. With this principle being the guiding force of operation, the Institute, with a small team of academicians, embarked upon its long and ambitious journey from a rather modest beginning. Now, HIESS has gained an enviable reputation as an exceptional teacher training institute in Pakistan and has established itself as an esteemed organization, producing research scholars in the various fields of social sciences and education.</p>'
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState();
  
  const modalFunciton = (data) => {
    setModalData(data);
    setModalShow(true)
  }
  
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalData?.Title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div dangerouslySetInnerHTML={{ __html: modalData?.Description }} />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
    <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    <section className="banner-galobal">
      
      <div className="container">
        <div className="fountdation-secdiv row">
          <div className="text-cnetered col-lg-12">
            <div className="text-cnetered">
              <h5 className="Montserrat-M grayColor">
                HAMDARD <b>FOUNDATION</b>
              </h5>
            </div>
            <div className="text-cnetered foundation-content">
              <h2 className="Montserrat-B greenColor">
              Dignity, Knowledge, Faith, Sincerity
                <br /> to Serve The Nation.
              </h2>
            </div>
            <div
              className="bottom-bannertwo-heading-img"
              style={{ zIndex: 1 }}
              data-aos="zoom-in"
            >
              <img className="bimg4" src={foundation} alt="image" />
            </div>
            <div className="mt-5 mb-5 container">
              <div className="row">
                <div
                  className="col-lg-3 col-md-6 pt-3"
                  data-aos="flip-left"
                  data-aos-delay="500"
                  onClick={() => modalFunciton(matab1)}
                >
                  <div class="card overflow-hidden" >
                    <div class="card-headers">
                      <img src={img1} alt="rover" />
                      <div className="fundation-text-div">
                        <div className="plus-div">
                          <img
                            classname="pluswhite-logo"
                            src={plus}
                            alt="plus"
                          />
                        </div>
                        <h6 className="Montserrat-M">Madinat al-Hikmah</h6>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 pt-3"
                  data-aos="flip-left"
                  data-aos-delay="500"
                  onClick={() => modalFunciton(matab2)}
                >
                  <div class="card overflow-hidden">
                    <div class="card-headers">
                      <img src={img2} alt="rover" />
                      <div className="fundation-text-div">
                        <div className="plus-div">
                          <img
                            classname="pluswhite-logo"
                            src={plus}
                            alt="plus"
                          />
                        </div>
                        <h6 className="Montserrat-M">Bait-ul-Hikmah</h6>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 pt-3"
                  data-aos="flip-left"
                  data-aos-delay="500"
                  onClick={() => modalFunciton(matab3)}
                >
                  <div class="card overflow-hidden"  >
                    <div class="card-headers">
                      <img src={img3} alt="rover" />
                      <div className="fundation-text-div">
                        <div className="plus-div">
                          <img
                            classname="pluswhite-logo"
                            src={plus}
                            alt="plus"
                          />
                        </div>
                        <h6 className="Montserrat-M">Faculty of Eastern Medicine</h6>
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-6 pt-3"
                  data-aos="flip-left"
                  data-aos-delay="500"
                  onClick={() => modalFunciton(matab4)}
                >
                  <div class="card overflow-hidden">
                    <div class="card-headers">
                      <img src={img4} alt="rover" />
                      <div className="fundation-text-div">
                        <div className="plus-div">
                          <img
                            classname="pluswhite-logo"
                            src={plus}
                            alt="plus"
                          />
                        </div>
                        <h6 className="Montserrat-M">Faculty of Social Sciences & Humanities</h6>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="foundation-minus globlbtnsdiv">
                <a
                  href="https://www.hamdardfoundation.org/"
                  type="button"
                  className="Poppins-R custmbtn globalbtn btn btn-light"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
  

}
