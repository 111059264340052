import React from 'react';
import img1 from '../assets/images/latestupdate.png'
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

export default function LatestUpdates() {
    const [data, setdata] = React.useState([]);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState();
  
    const asset_path = "upload/";

    React.useEffect(() => {
        getAllPressReleasesOne();
      }, []);
    const getAllPressReleasesOne = () => {
        axios
          .get(`${process.env.REACT_APP_SERVER_URL}public/pressReleases?featured=true`)
          .then(function (response) {
            setdata(response?.data?.PressReleases[0]);
            console.log(
              response?.data?.PressReleases[0],
              "PressReleases Latest updates"
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      };

    const modalFunciton = (data) => {
        setModalData(data);
        setModalShow(true)
    }
    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {modalData?.Title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><span>{moment(modalData?.Date).format("MM-DD-YYYY")}</span></p>
                <p>
                <div dangerouslySetInnerHTML={{ __html: modalData?.Description }} />
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
            </Modal>
        );
    }
    function trimString(string, length) {
        return (string?.length > length ? 
            string.substring(0, length) + '...' :
            string);
        }
        return (
            <>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                />
            <section className="latestupdate-banner">
                <div className="container">

                    <div className="row">
                        <div>
                            <h1 className="Montserrat-B greenColor" style={{marginLeft:"40px"}}>Latest Updates</h1>
                        </div>
                        <div className="col-lg-12">
                            <div className="container">
                                <div 
                                    onClick={() => modalFunciton(data)}
                                    className="row">
                                    <div className="col-lg-1">
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="latesupdate-inner">
                                            <p className="Poppins-R" style={{marginBottom:"3px"}}>{moment(data?.Date).format("MM-DD-YYYY")}</p>
                                            <h4 className="Montserrat-B grayColor">{data?.Title}</h4>
                                            <p className="Poppins-R" >
                                            <div dangerouslySetInnerHTML={{ __html: trimString(data?.Description, 300) }} />
                                            </p>
                                            <a href="#pressreleses" className="pressrelesetag Montserrat-M lgreenColor">More Press Releases</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="latestupdate-img">
                                            {data?.AttachmentType == "image" && 
                                                <img src={asset_path+data?.AttachmentLink} alt="" className="pressRelease-img imgbglearn" />
                                            }
                                            {data?.AttachmentType == "youtube" &&
                                                <iframe
                                                className="col-12"
                                                height="250"
                                                src={data?.AttachmentLink}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                                                controls="1"
                                            ></iframe>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </section>   
        </>
    );
}
