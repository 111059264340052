import React from "react";
import Man from "../assets/images/About us/About Hamdard/001quality policy.jpg";
import Man2 from "../assets/images/About us/About Hamdard/002quality policy.jpg";
import Man3 from "../assets/images/About us/About Hamdard/003quality policy.jpg";
import { Carousel } from "react-responsive-carousel";
import h1 from "../assets/images/1.png";
import services from "../assets/images/bgabouthmimg.png";

export default function Quality() {
  return (
    <section className="banner-quality">
      <img className="banner-quality-top-img" src={services} alt="image" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-5">
            <div className="container">
              <div className="row">
                <div className="quality-heading-contant" data-aos="fade-up">
                  <div className="contant-heading">
                    <h5 className="Montserrat-M whiteColor">
                      QUALITY <b>POLICY</b>
                    </h5>
                  </div>
                  <div className="contant2">
                    <h2 className="Montserrat-B whiteColor">
                      A leading household
                      <br />
                      name of quality products
                    </h2>
                  </div>
                  <div className="greenline-bannertwo"></div>
                  <div className="contant-ptag">
                    <p className="Poppins-R whiteColor">
                    Hamdard Pakistan’s products and brands are trusted and chosen by millions of people around the world to fulfill their nutritional, health & wellness needs, throughout their lifetime.
                    </p>
                    <p className="Poppins-R whiteColor">
                    To sustainably add value and effectively and efficiently build trust, Hamdard Pakistan:
                    </p>
                  </div>
                  <ol className="ulb2">
                    <li>
                      <p className="Poppins-R whiteColor">
                        Guarantees <b>product’s safety and full compliance</b> by respecting our policies, principles and standards with full transparency.
                      </p>
                    </li>
                    <li>
                      <p className="Poppins-R whiteColor">
                        Ensures and enhances <b>preference and consistency</b>{" "}
                        to delight individuals & families by valuing what they value and by offering products & services that always meet or exceed their expectations.
                      </p>
                    </li>
                    <li>
                      <p className="Poppins-R whiteColor">
                        Strives for <b>zero defects and no waste</b> by constantly looking for opportunities to apply our continuous improvement approach to deliver a competitive advantage.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div
            className="col-md-12 col-lg-7"
            style={{ zIndex: 2 }}
            data-aos="fade-up"
          >
            <Carousel autoPlay={true} infiniteLoop={true}>
              <div>
                <div className="bannertwo-img-bg">
                  <div className="quality-img-testing"></div>
                  <img className="b1img1" src={Man} alt="image" />
                </div>
              </div>

              <div>
                <div className="bannertwo-img-bg">
                  <div className="quality-img-testing"></div>
                  <img className="b1img1" src={Man2} alt="image" />
                </div>
              </div>

              <div>
                <div className="bannertwo-img-bg">
                  <div className="quality-img-testing"></div>
                  <img className="b1img1" src={Man3} alt="image" />
                </div>
              </div>
            </Carousel>
            {/* <div className="bannertwo-img-bg">
              <div className="quality-img-testing"></div>
              <img className="b1img1" src={Man} alt="image" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
