import React, { useEffect, useRef } from "react";
import img1 from "../assets/images/item1.svg";
import right from "../assets/images/right.png";
import left from "../assets/images/left.png";
import star from "../assets/images/start.png";
import share from "../assets/images/share.png";
import ProductsDetailsSlider from "./productsDetailsSlider";
import ProductDetailSliderTwo from "./productDetailSliderTwo";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";
import moment from "moment";
import Carousel from "react-elastic-carousel";
import greenplus from "../assets/images/roundedgreen.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

export default function ProductDetails() {
  const location = useLocation();
  const rowId = location.state;
  let navigate = useNavigate();
 const productId = useParams()

 console.log("perem Id ************", productId, window.location.origin)
  const [data, setdata] = React.useState([]);
  const [rowData, setdataDetail] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false)
  const [productError, setProductError] = React.useState(false)
  const [allProductError, setAllProductError] = React.useState(false)


  const asset_path =`${window.location.origin}/upload/`;

  let carousel = useRef(null);
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1},
    { width: 650, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1440, itemsToShow: 4, itemsToScroll: 2 },
  ];

  React.useEffect(() => {
    getAllproducts();
    if(productId?.id){

    getProductsById();
    }
  }, [ productId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productId]);

  const HandelClick = (row) => {
    console.log(row, "row========");
     navigate(`/product-details/${row.PKProductId}`, { state: row })
  };

  const getAllproducts = () => {
    setAllProductError(false)
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/products`)
      .then(function (response) {
        setdata(response?.data?.Products);
        console.log(response, "====== get All products =====");
      })
      .catch(function (error) {
        setAllProductError(true)
        console.log(error);
      });
  };
  const getProductsById = () => {
    setLoading(true)
    setProductError(false)
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/productById?id=${productId?.id}`)
      .then(function (response) {
        setdataDetail(response?.data?.Product);
        setLoading(false)
        console.log(response, "====== Products Detail =====");
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        setProductError(true)
        
      });
  };

  if(isLoading){
   return( <section className="items-details" style={{height:"70vh"}}>
      <div className="container" style={{height:"100%", display:'flex', justifyContent:"center", alignItems:"center"}}>
        <RotatingLines
        strokeColor="green"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
      </div>

    </section>)
  }

  return (
    <>
      <section className="items-details">
        <div className="container">
          {!productError ?<div className="row">
            <div className="mb-3 col-lg-4 col-md-12">
              <div className="product-main-img">
                <Zoom>
                  <img alt={`${asset_path+ rowData?.Image}`} 
                  // src={img1}
                  src={asset_path+ rowData?.Image}
                  style={{width:"100%"}}
                   />
                </Zoom>
                {/* <img className="detils-item-img" alt="" src={img1} /> */}
              </div>
            </div>
            
            <div className="mb-3 col-lg-8 col-md-12">
              <div>
                <div className="contant3">
                  <h2 className="Montserrat-B greenColor">{rowData?.Title}</h2>
                </div>
                <div className="contant3" style={{ marginTop: "-8px" }}>
                  <p className="Montserrat-B greenColor">{rowData?.Unit}</p>
                </div>
                <div>
                  <h2 className="Poppins-R lgreenColor mt-3">
                    Rs. {rowData?.Price}
                  </h2>
                </div>
                <div className="contant-ptag">
                  {/* <h2 className="Montserrat-B greenColor">Description</h2> */}
                </div>
                <div className="product-description-data">
                  <div dangerouslySetInnerHTML={{ __html: rowData?.Description }} />
                </div>
              </div>
            </div>
          </div> : <div className="h-65">
          
          <h1 style={{width:"100%", textAlign:`center`}}> Something Went Wrong</h1>
          </div>}
        </div>

        <div className="slider-inner-details">
          <div className="item-headings-div">
            <div className="slider-custom-buttons">
              <button onClick={() => carousel.slidePrev()} className="slider-custom-button-prev"><img className="slider-custom-prev" src={left} /></button>
              <button onClick={() => carousel.slideNext()} className="slider-custom-button-next"><img className="slider-custom-next" src={right} /></button>
            </div>
            <div className="events-heading-h2">
              <h2 className="Montserrat-B greenColor">Other Products</h2>
            </div>
          </div>

         {!allProductError? <div className="item-slider">
            <Carousel
              ref={ref => (carousel = ref)}
              breakPoints={breakPoints}
              itemPadding={[2, 10]}
              enableAutoPlay={false}
              pagination={false}
              autoPlaySpeed={5000}
              showEmptySlots
              showArrows={false}
            >
              {data?.map((row) => (
                <div className="img-div-events">
                  <div className="item-innerimg-div">
                    <img
                      onClick={() => HandelClick(row)}
                      className="events-item-div"
                      alt=""
                      src={asset_path+row.Image}
                      // src={img1}
                    />
                    <img
                      onClick={() => HandelClick(row)}
                      className="greenplus"
                      alt=""
                      src={greenplus}
                    />
                  </div>

                  <div className="events-items-text" onClick={() => HandelClick(row)}>
                      <span>{row.Unit}</span>
                      <p >
                        <b>{row.Title}</b>
                      </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div> :<div className="h-65">
          
          <h1 style={{width:"100%", textAlign:`center`}}> Something Went Wrong</h1>
          </div>}

        </div>
      
      </section>
    </>
  );
}
