import React,{useEffect,useState} from 'react';
import Banner from './banner';
import './publication.css';
import Multiselect from './multiselect';
import PublicationItem from './publicationItem';
import Events from './events';
import EventsSec from './eventsSec';
import LatestUpdates from './latestUpdates';
import PressRelease from './pressRelease';

export default function Publication() {
  const [multiSelectData , setMultiselectData] = useState([])
  // console.log(multiSelectData);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className='wrapper_publication'>
        <div className="empty-section-publication">
        </div>
            <Banner/>
            <Multiselect multiSelectData={multiSelectData} setMultiselectData={setMultiselectData}/>
            <PublicationItem multiSelectData={multiSelectData ? multiSelectData : ""}/>
            <Events/>
            <EventsSec/>
            <LatestUpdates/>
            <PressRelease/>
        </div>
    )
}
