import React, { useEffect } from "react";
import "../AboutHamdard.css";
import "./directors.css";
import { ReactComponent as Fb } from "../../assets/images/fb.svg";
import { ReactComponent as Insta } from "../../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/images/youtube.svg";

import man from "../../assets/images/About us/Board of Directors/02-A.jpg";
import man2 from "../../assets/images/About us/Board of Directors/02-B.jpg";
import directorslider from "./directorslider";

import Aos from "aos";
import bannerimg from "../../assets/images/About us/Board of Directors/banner.jpg";
import Directorsslider from "./directorslider";
import Socialicons from "../../socialIcons";
import SecondBannerCompo from "../../SecondBannerCompo";

export default function Directors() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="page-leadership">
        <section>
          <div className="banner-div">
            <div className="empty-section-res"></div>
            <Socialicons value={"white"} />
            <div className="img-div-whatweoffer">
              <img
                src={bannerimg}
                className="bannerimg-whatwe-offer"
                alt="bannerimg"
                loading="lazy"
              />
            </div>
          </div>
        </section>
        <section className="whoweare">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <SecondBannerCompo
                  mainText={
                    <>
                      
                      Our leadership team drives our <br></br> company, guides
                      our strategy and<br></br> leads our people.
                    </>
                    
                  }
                  classNameBorder="  mutawallis_Green_Border"
                  classNameMainDiv="  sadia_Main_Div"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whoweare p-5">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <img src={man} className="innerwhoweare-img col-8 offset-1" />
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5 col-md-12">
                <div className="whoweare-contant">
                  <h2 className="Montserrat-B greenColor">Ms Sadia Rashid</h2>

                  <h4 className="Montserrat greenColor">Chairperson</h4>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </section>

        <section className="bg-abouthamdard banner-one onemore mt-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6" data-aos="flip-left">
                <div className="about-left-section-ib">
                  <div className="contant">
                    <h2 className="Montserrat-B greenColor">
                      From a small Matab to a Worldwide Leader in Herbal
                      Medicines, Hamdard continues to write history.
                    </h2>
                  </div>
                  <div className="contant-ptag-ib">
                    <p className="Poppins-R grayColor">
                      Hamdard Pakistan is the legacy of Shaheed hakim Mohammed
                      Said, and we are privileged to be custodians of it. We
                      cannot rest on Hamdard’s laurels. We have to work hard. We
                      have to venture further into the field of herbal medicines
                      and products, to develop them through continuing
                      scientific studies and research, so that we carry forward
                      the vision and mission of Shaheed Hakim Mohammed Said, to
                      not only bring Hamdard up to his expectations as a house
                      of herbal medicines and products of international repute
                      as well as engine of education, but also maintain its true
                      spirit of philanthropy. Hamdard Pakistan’s team, passion
                      and dedication is to carry forward the vision of Shaheed
                      Hakim Mohammed Said to serve humanity. This is not just a
                      motto written in gold letters to be framed and hung up on
                      the wall, and then forgotten. It is more than a principle;
                      it is our practice.
                      <br></br>
                      <br></br>
                      As proof of the seriousness of our dedication to not
                      simply keeping Shaheed Hakim Said’s work going, but
                      keeping it moving forward and increasing its success in
                      service, let me present a few examples:
                      <br></br>
                      <br></br>
                      <ol>
                        <li>
                          Hamdard Rooh Afza has continued winning the award for
                          the top export item, consecutively for the last 22
                          Years.
                        </li>
                        <li>
                          Sales of both its best seller Rooh afza, as well as of
                          its medicinal products have increased many times over.
                          <br></br>
                        </li>
                        <li>
                          In its educational efforts, the number of buildings,
                          faculties and students have grown.<br></br>
                        </li>
                        <li>
                          Growth has also registered in the many forms of
                          philanthropic services that Hamdard Pakistan has been
                          offering to those in need.<br></br>
                        </li>
                        <li>
                          Recently, the number of patients seen at our free
                          clinics and mobile dispensaries have significantly
                          multiplied.<br></br>
                        </li>
                      </ol>
                      <br></br>
                      Insha’Allah our determination to continue our efforts to
                      live up to our name “Hamdard” – those who share in
                      another’s pain, and do their best to relieve it.
                      <br></br>
                      <br></br>
                      We are confident of Allah’s help, because we are sincere
                      in our service. Allah Ho Akbar. God is great!
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-6" data-aos="flip-right">
                <div className="about-img-bg">
                  <img className="imgib" src={man2} alt="image" />
                  <div className="about-img-testing"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Directorsslider />
    </>
  );
}
