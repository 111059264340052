import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Man from '../assets/images/Asset13.png';
import img1 from '../assets/images/Our responsibility/05 - Free Mobile Matabs/001.jpg';
import img2 from '../assets/images/Our responsibility/05 - Free Mobile Matabs/002.jpg';
import img3 from '../assets/images/Our responsibility/05 - Free Mobile Matabs/003.jpg';
import greensolid from '../assets/images/Assetsolid.png';
import search from '../assets/images/search.svg';
import { ReactComponent as Fb } from '../assets/images/fb.svg';
import { ReactComponent as Insta } from '../assets/images/insta.svg';
import { ReactComponent as Twitter } from '../assets/images/twitter.svg';
import { ReactComponent as Youtube } from '../assets/images/youtube.svg';
import '../Slider/slider.css';

export default function SecrightimgLeftheading() {
    return (
        <div className="rightimgleftheading">
            <div data-aos="fade-up">
                <section className="banner-sliderleftimg">
                    <div className="container bg-white">
                        <div className="row">
                            <div className="p-5 col-lg-7 col-md-12 ourRes_slider_center" >


                                <h2 className="Montserrat-B greenColor">Hamdard Free Mobile Dispensaries</h2>

                                <div className="sliderleftwala-ptag">
                                    <p className="Poppins-R">Hamdard Free Mobile Dispensaries program is a flagship initiative of HFP. Shaheed Hakim Mohammed Said started Hamdard Free Mobile Dispensaries in 1963 with one vehicle to provide free treatment to poor and needy patients in remote areas of Karachi. Hamdard Foundation Pakistan gradually expanded and enhanced this philanthropic activity and now Hamdard Free Mobile Dispensaries are treating more than 500,000 poor & needy patients every year in 11 major cities including Karachi, Lahore, Hyderabad, Faisalabad, Rawalpindi, Peshawar, Sargodha, Multan, Sukkur, Quetta, and Azad Kashmir. The Hamdard Foundation Pakistan has a fleet of 18 equipped vehicles with Hamdard’s medicines and experienced Hakims. Hamdard Foundation Pakistan provides free consultancy and medicines through this program and refers patients to Hamdard University Hospital (Taj Medical Complex) if found critical and provides free treatment under the “Free Patient Scheme” program. Each Hamdard Free Mobile Dispensary is being operated by an experienced Hakim Medical Officer and dispenser for treating patients in slum areas.</p>
                                </div>
                            </div>
                    
                    <div className="paddingsliderimg col-lg-5 col-md-12 align-self-xl-center ourRes_slider_center" >
                        <div className="">
                            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                <img src={img1} className="" />
                                <img src={img2} className="" />
                                <img src={img3} className="" />
                            </Carousel>
                        </div>
                    </div>
                    </div>
                    </div>
                </section >
            </div >
        </div >
    )
}
