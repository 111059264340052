import React from 'react';
import Man from '../assets/images/Asset13.png';
import green from '../assets/images/line.png';
import img1 from '../assets/images/Our responsibility/01 - Blanket Distribution/001.jpeg';
import img2 from '../assets/images/Our responsibility/01 - Blanket Distribution/002.jpeg';
import img3 from '../assets/images/Our responsibility/01 - Blanket Distribution/003.jpeg';

import hamdard from '../assets/images/hamdard.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function SliderleftImg() {
    return (
        
        <section className="banner-sliderleftimg">
            <div className="container bg-white ourResponsibility" >
                <div className="row" data-aos="fade-up" >
                    <div className=" col-lg-6 col-md-12 p-5 ourRes_slider_center" >
                       
                            
                                <div className="contant-heading">
                                    <h5 className="Montserrat-M grayColor">OUR <b>RESPONSIBILITY</b></h5>
                                </div>
                                <h2 className="Montserrat-B greenColor">Blankets Distribution</h2>
                                <div className="greenline-responsibility"></div>
                                <div className="contant-ptag">
                                    <p className="Poppins-R">As per our practice in vogue, Hamdard Foundation Pakistan distributes blankets, meat, and ration bags to the poor and needy people of villages adjacent to the Hamdard University which is now extended to other cities of Interior Sindh that included Mithi, Islamkot, Nawkot, Nagarparkar, Mirpurkhas, and Qazi Ahmad.</p>
                                </div>
                            
                  
                    </div>

                    <div className="paddingsliderimg col-lg-6 col-md-12 ourRes_slider_center">
                    <Carousel infiniteLoop={true} autoPlay={true} data-aos="fade-up" showThumbs={false}>
                        <div className="">
                            <img src={img1} className=""/>
                            <p className="legend">Legend 1</p>
                        </div>
                        <div className="">
                            <img src={img2} className=""/>
                            <p className="legend">Legend 2</p>
                        </div>
                        <div className="">
                            <img src={img3} className=""/>
                            <p className="legend">Legend 3</p>
                        </div>
                    </Carousel>
                    </div>
                </div>      
            </div>
        </section>
    )
}
