import React, { useState, useEffect } from "react";
import Footer from "./footer";
import FooterTwo from "./footerTwo";
import { useLocation } from "react-router-dom";

export default function FooterIndex() {
  const location = useLocation();

  useEffect(() => {
    setpath(location.pathname);
  }, [location]);

  const [path, setpath] = useState("");
  return <>{path !== "/life-at-hamdard" && path !== "/opportunities" ? <Footer /> : <FooterTwo />}</>;
}
