import React,{useEffect} from 'react';
import './home.css';
import Man from '../assets/images/RooHAfza.webp';
import h1 from '../assets/images/1.png';
import services from '../assets/images/Assett8.svg';
import Aos from 'aos';
import {useNavigate } from "react-router-dom";



export default function BannerTwo() {

    const navigate = useNavigate();

    useEffect(() => {
        Aos.init({duration:1500});
      },[]);
    return (
        <section className="banner-two">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-5">
                        <div className="container">
                            <div className="row">
                                <div className="bannertow-heading-contant" data-aos="flip-left" data-aos-once="true">
                                    <div className="contant-heading">
                                        <h5 className="Montserrat-M grayColor">What we offer – <b>Our Products</b></h5>
                                    </div>
                                    <div className="contant2">
                                        <h2 className="Montserrat-B greenColor">A leading household name of quality products</h2>
                                    </div>
                                    <div className="greenline-bannertwo"></div>
                                    <div className="contant-ptag">
                                        <p className="Poppins-R grayColor">Hamdard’s products are created from natural ingredients that are safe to consume and are tested as highly effective treatment for illnesses, infections and suchlike.</p>
                                    </div>
                                    <ol className="ulb2">
                                        <li>
                                        <b className="Montserrat-B greenColor">Consumer Products</b>
                                            <p className="Poppins-R grayColor">Our consumer range products are created from Pakistan’s finest and purest ingredients. Every product launched by Hamdard has drafted success in its way because of the quality from which it is sourced.</p>
                                        </li>
                                        <li>
                                        <b className="Montserrat-B greenColor">Over the Counter Products (OTC)</b>
                                            <p className="Poppins-R grayColor">Over-the-counter products that are safe to use without prescription are created using extensive knowledge and experience of herbs, natural sources and rare ingredients. The concept of preventing the diseases and promoting health.</p>
                                        </li>
                                        <li>
                                        <b className="Montserrat-B greenColor">Medicines / Health Care Products (HCP)</b>
                                            <p className="Poppins-R grayColor">Prescription drugs for handling gastric, oral, or skin issues are among the best-selling products at Hamdard, that promote simpler solutions and treatments.</p>
                                        </li>
                                    </ol>
                                    <div className="btn-div">
                                        <button type="button" onClick={() =>  navigate("products")} className="Poppins-R custmbtn-two btn btn-light">READ MORE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-7" style={{zIndex:2}} data-aos="flip-right" data-aos-once="true">
                        <div className="bannertwo-img-bg">
                            <div className="bannertwo-img-testing">
                                    </div>
                                <img className="b1img1" src={Man}  alt="image"/>      
                        </div>
                    </div>


                    <div className="bottom-bannertwo-heading-img" style={{zIndex:1}} data-aos="zoom-in">
                            <img className="bimg3" src={services}  alt="image"/>
                        </div>
                 
                </div>      
            </div>
        </section>
    )
}
