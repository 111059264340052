import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Man from "../assets/images/man.png";
import greensolid from "../assets/images/Assetsolid.png";
import search from "../assets/images/search.svg";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import bannerimg from "../assets/images/Publication and events/Banner.jpg";
import "../Slider/slider.css";
import Socialicon from "../socialIcons";

export default function Banner() {
  return (
    <div>
      {/* <div className="search-div">
        <img src={search} className="searchicon"  alt=""/>
        </div>  */}
      <Socialicon />
      <Carousel autoPlay={true} infiniteLoop={false} className="services-slider">
        <div >
          <div  style={{width:"100%"}}><img src={bannerimg} className="production-slider" /></div>
          <p className="legend">Legend 1</p>
          <div className="textdiv-publication ">
            <div>
              <img src={greensolid} className="slidergreen-publication" alt="" loading="lazy" />
              <p className="Montserrat-M ptags-slider grayColor"><b>HAMDARD PAKISTAN</b></p>
              <div className="h1-headings" >
                <h1 className="Montserrat-B greenColor h1font">Education is simply the soul</h1>
                <h1 className="Montserrat-B greenColor h1font">of a society as it passes from</h1>
                <h1 className="Montserrat-B greenColor h1font">one generation to another.</h1>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}
