import React, { useEffect, useState } from "react";
import "./footer.css";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import Aos from "aos";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Oval } from "react-loader-spinner";

import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
export default function Footer() {
  // const [formdata, setFormData] = useState();
  const [error, setError] = useState("");
  const [dataSucess, setDataSucess] = useState("");
  const [disable, setDisable] = useState(false);
  const [loader, setLoader] = useState(false);

  const [contactFormData, setContactFormData] = useState({});

  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  const handelClick = (e) => {
    e.preventDefault();
    e.target.reset();
    setDisable(true);
    setLoader(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_SERVER_URL}/public/contactUs`,
      data: contactFormData,
    })
      .then((res) => {
        setDataSucess(res?.data?.message[0]);
        setLoader(false);
        e.target.reset();
        setDisable(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.message[0]);
        setLoader(false);
        setDisable(false);
      });
  };

  const onChangeHandle = (e) => {
    const name = e.target.name;
    const val = e.target.value;

    setError("");
    setDataSucess("");

    setContactFormData({ ...contactFormData, [name]: val });
  };

  return (
    <section className="section-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="inner-footer-div">
                  <div className="footer-contant-heading">
                    <h5 className="Montserrat-M ">CONTACT US</h5>
                  </div>
                  <div className="footer-contant">
                    <h2 className="Montserrat-B">Get in touch</h2>
                  </div>
                  <div className="greenline-footer"></div>
                  <div className="footer-contant-ptag">
                    <p className="Poppins-R lightgray">
                      Let us be a part of your discovery. Leave a message for
                      any queries or concerns.
                    </p>
                  </div>
                  <form onSubmit={(e) => handelClick(e)}>
                    <div className="row">
                      <div className="col-xs-12 col-sm-4 sm_Margin">
                        <input
                          required
                          minLength={3}
                          type="text"
                          className="form-control"
                          name="FullName"
                          placeholder="Full Name"
                          onChange={(e) => onChangeHandle(e)}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-4 sm_Margin">
                        <input
                          required
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="Email"
                          onChange={(e) => onChangeHandle(e)}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-4 sm_Margin">
                        <input
                          required
                          maxLength={11}
                          type="number"
                          className="form-control"
                          placeholder="Contact Number"
                          name="ContactNumber"
                          onChange={(e) => onChangeHandle(e)}
                        />
                      </div>
                    </div>
                    <div className="mt-4 form-group">
                      <textarea
                        required
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Message"
                        rows="3"
                        name="Message"
                        minLength={20}
                        onChange={(e) => onChangeHandle(e)}
                      ></textarea>
                      <div style={{ color: "red", marginTop: "1rem" }}>
                        {dataSucess ? (
                          <h5 style={{ color: "white" }}>{dataSucess}</h5>
                        ) : (
                          <h5>{error}</h5>
                        )}
                      </div>
                    </div>
                    <div className="footer-bottom-btn">
                      <button
                        type="submit"
                        className="custmbtn-footer"
                        disabled={dataSucess ? disable : false}
                      >
                        {loader
                          ? "SENDING...."
                          : dataSucess
                          ? "SENT"
                          : "SEND MESSAGE"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-12" data-aos="fade-up">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-1"></div> */}
                <div className="col-lg-12">
                  <div className="footer-contact">
                    <div className="p-3 pb-5 pt-5">
                      <h6 className="Poppins-R lightgray">
                        <a href="tel:+922138244000">Tel: (+92 21) 38244000 </a>{" "}
                        <br></br>
                        <a href="tel:+922138241555">Fax: (+92 21) 38241555</a>
                      </h6>

                      <br></br>
                      <h6 className="Poppins-R lightgray">
                        <a href="mailto:headoffice@hamdard.com.pk">
                          <p className="text-break">headoffice@hamdard.com.pk</p>
                        </a>
                      </h6>
                      <br></br>
                      <h6 className="Poppins-R lightgray">
                        <p>Hamdard Laboratories (Waqf) Pakistan.</p>
                        <p>
                          172/2 PECHS, Main Tariq Road, Bahria Town Tower, Floor
                          No. 15, Karachi.
                        </p>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container footer_use_links">
            <div className="row">
              <div  className="col-lg-2 footerBottom">
                <div className="menu-footer-li">
                  <ul className="footer-li">
                    <li>
                      <Link
                        to="about-hamdard"
                        // onClick={() => navigate("about-hamdard")}
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        About Hamdard
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="services"
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        What We Offer
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://www.hamdardfoundation.org/"
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        Hamdard Foundation
                      </a>
                    </li>
                    <li>
                      <Link
                        to="our-responsibility"
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        Our Responsibility
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div  className="col-lg-2 footerBottom">
                <div className="menu-footer-li">
                  <ul className="footer-li">
                    <li>
                      <Link
                        to="publication&Event"
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        Publication & Events
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="international-busniess"
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        Global Presence
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="opportunities"
                        className="Poppins-R footer-alink-two footerfont whiteColor"
                      >
                        Work with us{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div  className="col-lg-3 footerBottom">
                <div className="social-icons">
                  <p className="Poppins-R footerfont" style={{ color: "#fff" }}>
                    Connect with us
                    <div className="slider-social-icons-footer">
                      <div className="rounded-divs-two rounded-divsTwo">
                        <a
                          href="https://www.facebook.com/Hamdardpkofficial/"
                          target={"_blank"}
                        >
                          <Fb className="fbicon-two footerFBICon" />
                        </a>
                      </div>
                      <div className="rounded-divs rounded-divsTwo">
                        <a
                          href="https://twitter.com/hamdard_pk"
                          target={"_blank"}
                        >
                          <Twitter className="twittericon-two footertwiICon" />
                        </a>
                      </div>
                      <div  className="rounded-divs rounded-divsTwo">
                        <a href="https://www.instagram.com/hamdardpakistan/?hl=en">
                          <Insta className="instaicon-two footerInstaiICon" />
                        </a>
                      </div>
                      <div className="rounded-divs rounded-divsTwo">
                        <a
                          href="https://www.youtube.com/c/HamdardPakistan/videos"
                          target={"_blank"}
                        >
                          <Youtube className="yticon-two footerYtiICon" />
                        </a>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div  className="col-lg-5 footerBottom">
                <div className="address-footer">
                  <p className="Poppins-R footerfont" style={{ color: "#fff" }}>
                    ©2021, Hamdard Laboratories (Waqf) Pakistan <br />
                    Made with passion by The Craftsmen
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
