import React, { useEffect } from "react";
import "../AboutHamdard.css";
import "./directors.css";
import { ReactComponent as Fb } from "../../assets/images/fb.svg";
import { ReactComponent as Insta } from "../../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/images/youtube.svg";

import man from "../../assets/images/About us/Board of Directors/01-A.jpg";
import man2 from "../../assets/images/About us/Board of Directors/01thumbnails.jpg";
import directorslider from "./directorslider";

import Aos from "aos";
import bannerimg from "../../assets/images/About us/Board of Directors/banner.jpg";
import Directorsslider from "./directorslider";
import Socialicons from "../../socialIcons";
import SecondBannerCompo from "../../SecondBannerCompo";

export default function Directors() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <section className="page-leadership">
        <section>
          <div className="banner-div">
            <div className="empty-section-res"></div>
            <Socialicons className="hakim" value={"white"} />
            <div className="img-div-whatweoffer">
              <img
                src={bannerimg}
                className="bannerimg-whatwe-offer"
                alt="bannerimg"
                loading="lazy"
              />
            </div>
          </div>
        </section>
        <section className="whoweare">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <SecondBannerCompo
                  mainText={
                    <>
                      
                      Our leadership team drives our <br></br>
                      institution, guides our strategy and <br></br>
                      leads our people.<br></br>
                    </>
                  }

                  classNameBorder="  mutawallis_Green_Border"
                  classNameMainDiv="  hakim_Main_Div"

                />
              </div>
            </div>
          </div>
        </section>

        <section className="whoweare p-5">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <img src={man} className="innerwhoweare-img col-8 offset-1" />
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-5 col-md-12">
                <div className="whoweare-contant">
                  <h2 className="Montserrat-B greenColor">
                    Shaheed Hakim Mohammed Said
                  </h2>

                  <h4 className="Montserrat greenColor">Founder</h4>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </section>

        <section className="bg-abouthamdard banner-one onemore mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6" data-aos="flip-left">
                <div className="about-left-section-ib">
                  <div className="contant">
                    <h2 className="Montserrat-B greenColor">
                      LOVE PAKISTAN – BUILD PAKISTAN
                    </h2>
                  </div>
                  <div className="contant-ptag-ib">
                    <p className="Poppins-R grayColor">
                      Pakistan is an invaluable gift of Allah Almighty to the
                      Muslims of subcontinent. It is our foremost duty to love,
                      build and protect this promised land from the bottom of
                      our hearts and make our all-out efforts to carry this
                      country to the heights of glory as desired by its founder
                      Quaid-e-Azam Mohammed Ali Jinnah.
                      <br></br>
                      <br></br>
                      It is one of my main objectives to establish Hamdard in
                      Karachi, Pakistan is to make it an institution of not only
                      of health and medicine, but a fountain – like organization
                      of philanthropy from which the waves of patriotism,
                      service to people and reconstruction of Pakistan would
                      originate and irrigate the foundations of Pakistan. My
                      message to the people of Pakistan particularly to youths
                      is “Love Pakistan --- Build Pakistan”. I have a mission of
                      spreading education in the country, for education is the
                      master key of progress, and a clear vision about Hamdard,
                      besides a benevolent organization, I am desirous of making
                      Hamdard a reputed institution of research and scientific
                      studies on herbal medicines and products to make them and
                      the system of Eastern Medicine (Unani Medicine) to a
                      successful treatment of modern day’s diseases.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-6" data-aos="flip-right">
                <div className="about-img-bg">
                  <img className="imgib" src={man2} alt="image" />
                  <div className="about-img-testing"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <Directorsslider />
    </>
  );
}
