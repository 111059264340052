import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/home";
import WhatWeOffer from "./components/WhatWeOffer/main";
import Header from "./components/Navbar/HeaderNew";
import MainAboutHamdard from "./components/AboutHamdard/mainAboutHamdard";
import MainProducts from "./components/Products/mainProducts";
import ProductDetails from "./components/Products/productDetails";
import OurResponsibility from "./components/OurResponsibility/ourResponsibility";
import Publications from "./components/PublicationAndEvents/publication";
import WorkWithUs from "./components/WorkWithUs/workWithUsMain";
import MainOpportunities from "./components/Opportunities/mainOpportunities";
import Hakim from "./components/AboutHamdard/Directors/hakim";
import Sadia from "./components/AboutHamdard/Directors/sadia";
import Fatema from "./components/AboutHamdard/Directors/fatema";
import Arshad from "./components/AboutHamdard/Directors/arshad";
import Farrukh from "./components/AboutHamdard/Directors/farrukh";
import Wellbeing from "./components/WellBeing/wellbeing";
import Ib from "./components/InternationlBusiness/ib";
import Footer from "./components/Footer/footerIndex";
import Mutawallis from "./components/hamdard-mutawallis/Mutawallis";

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="services" element={<WhatWeOffer />} />
          <Route path="about-hamdard" element={<MainAboutHamdard />} />
          <Route path="products" element={<MainProducts />} />
          <Route path="product-details/:id" element={<ProductDetails />} />
          {/* <Route path="product-details" element={<ProductDetails />} /> */}
          <Route path="our-responsibility" element={<OurResponsibility />} />
          <Route path="publication&Event" element={<Publications />} />
          <Route path="life-at-hamdard" element={<WorkWithUs />} />
          <Route path="opportunities" element={<MainOpportunities />} />
          <Route path="director/hakim" element={<Hakim />} />
          <Route path="director/sadia" element={<Sadia />} />
          <Route path="director/fatema" element={<Fatema />} />
          {/* <Route path="director/arshad" element={<Arshad />} /> */}
          <Route path="director/farrukh" element={<Farrukh />} />
          <Route path="well-being" element={<Wellbeing />} />
          <Route path="international-busniess" element={<Ib />} />
          <Route path="hamdard-mutawallis" element={<Mutawallis />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
