import { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import "./headerNew.css";
import { useMediaQuery } from "react-responsive";
import logopng from "../assets/images/logo2.png";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import "../Navbar/responsive.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';


export const HeaderNew = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const [path, setpath] = useState("");
  const [NewNavbar, setNavbar] = useState();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  // const handlOnMouseLeave = debounce(() => setShow(false), 1000)

  const debouncedHandleMouseEnter = () => {
    setShow(true);
    debouncedHandleMouseEnter.cancel();
  };

  useEffect(() => {
    setpath(location.pathname);
  }, [location]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "scroll",
        () => setNavbar(window.pageYOffset > 5)
        // setNews(true)
      );
    }
  }, []);

  const toggle = () => setExpanded(false);



  const ShowOneItems = () => {
    return (
      <>
        <LinkContainer to="about-hamdard">
          <NavDropdown.Item onClick={toggle}>About Hamdard</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown
                        title="Our Leadership"
                        id="nested_dropdown"
                        className="NavText"
                        show={showTwo}
                        onMouseEnter={() => setShowTwo(true)}
                        onMouseLeave={() => setShowTwo(false)}
                        onClick={() => setShowTwo(!showTwo)}
                      >
                       {showTwoItems()}
                       
                      </NavDropdown>

        <LinkContainer to="well-being">
          <NavDropdown.Item onClick={toggle}>Well Being</NavDropdown.Item>
        </LinkContainer>

        <LinkContainer to="hamdard-mutawallis">
          <NavDropdown.Item onClick={toggle}>Mutawallis</NavDropdown.Item>
        </LinkContainer>
      </>
    );
  };


  const showTwoItems = () => {
    return(
    <>
     <LinkContainer to="director/hakim">
                          <NavDropdown.Item onClick={toggle}>
                            Shaheed Hakim Mohammed Said
                          </NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer to="director/sadia">
                          <NavDropdown.Item onClick={toggle}>
                            Ms Sadia Rashid
                          </NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer to="director/fatema">
                          <NavDropdown.Item onClick={toggle}>
                            Ms Fatema Munir Ahmed
                          </NavDropdown.Item>
                        </LinkContainer>

                        {/* <LinkContainer to="director/arshad">
                          <NavDropdown.Item onClick={toggle}>
                            Dr. Arshad Saleem
                          </NavDropdown.Item>
                        </LinkContainer> */}

                        <LinkContainer to="director/farrukh">
                          <NavDropdown.Item onClick={toggle}>
                             Mr. Farrukh Imdad
                          </NavDropdown.Item>
                        </LinkContainer>
    </>
    )

  }

  return (
    <div>
      <Navbar
        expanded={expanded}
        bg="light"
        expand="lg"
        className={!NewNavbar && path === "/" ? "trans_nav_bg" : "white_nav_bg"}
      >
        <div className={isTabletOrMobile ? "d-flex w-100 p-3" : "container"}>
          <div className="navbar_main">
            <div className="first_section">
              <Link to="/">
                <Navbar.Brand onClick={(() => navigate("/"), toggle)}>
                  <img  className="nav_brand" src={logopng} alt="logo" />
                </Navbar.Brand>
              </Link>
            </div>

            <div className="second_section">
              <Navbar.Toggle
                aria-controls="navbarScroll"
                onClick={() => setExpanded(expanded ? false : "expanded")}
              />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: "max-content" }}
                  navbarScroll
                >
                  {!isTabletOrMobile ? (
                    <NavDropdown
                      title="ABOUT"
                      id="collasible-nav-dropdown"
                      show={show}
                      onMouseEnter={() => setShow(true)}
                      onMouseLeave={() => setShow(false)}
                      onClick={() => setShow(!show)}
                    >
                      {ShowOneItems()}

                      
                    </NavDropdown>
                  ) : (
                    <NavDropdown title="ABOUT">
                      {ShowOneItems()}


                     
                    </NavDropdown>
                  )}

                  {/* =========================    SECOND DROP DOWN WHAT WE OFFER ============================== */}

                  {!isTabletOrMobile ? (
                    <NavDropdown
                      id="collasible-nav-dropdown"
                      title="WHAT WE OFFER"
                      show={showThree}
                      onMouseOver={() => setShowThree(true)}
                      onMouseLeave={() => setShowThree(false)}
                      onClick={() => setShowThree(!showThree)}
                    >
                      <LinkContainer to="services">
                        <NavDropdown.Item href="#action1123" onClick={toggle}>
                          Services
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="products">
                        <NavDropdown.Item href="#action1" onClick={toggle}>
                          Products
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  ) : (
                    <NavDropdown title="WHAT WE OFFER">
                      <LinkContainer to="services">
                        <NavDropdown.Item href="#action1123" onClick={toggle}>
                          Services
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="products">
                        <NavDropdown.Item href="#action1" onClick={toggle}>
                          Products
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  )}

                  <Nav.Link
                    href="https://www.hamdardfoundation.org/"
                    target="blank"
                    onClick={toggle}
                  >
                    HAMDARD FOUNDATION
                  </Nav.Link>

                  <LinkContainer to="our-responsibility">
                    <Nav.Link href="#action2" onClick={toggle}>
                      OUR RESPONSIBILITY
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="publication&Event">
                    <Nav.Link onClick={toggle}>PUBLICATIONS & EVENTS</Nav.Link>
                  </LinkContainer>

                  <LinkContainer to="international-busniess">
                    <Nav.Link onClick={toggle}>GLOBAL PRESENCE</Nav.Link>
                  </LinkContainer>

                  {/* =================== Last dropdown work with us  =============================================== */}

                  {!isTabletOrMobile ? (
                    <NavDropdown
                      id="collasible-nav-dropdown"
                      title="WORK WITH US"
                      show={showFour}
                      onMouseOver={() => setShowFour(true)}
                      onMouseLeave={() => setShowFour(false)}
                      onClick={() => setShowFour(!showFour)}
                    >
                      <LinkContainer to="life-at-hamdard">
                        <NavDropdown.Item onClick={toggle}>
                          Life at Hamdard
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="opportunities">
                        <NavDropdown.Item onClick={toggle}>
                          Opportunities & Openings
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  ) : (
                    <NavDropdown title="WORK WITH US">
                      <LinkContainer to="life-at-hamdard">
                        <NavDropdown.Item onClick={toggle}>
                          Life at Hamdard
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to="opportunities">
                        <NavDropdown.Item onClick={toggle}>
                          Opportunities & Openings
                        </NavDropdown.Item>
                      </LinkContainer>
                    </NavDropdown>
                  )}
                  <Nav.Link href="https://ecom.hamdardglobal.com">
                  <FontAwesomeIcon icon={faShoppingCart} /> SHOP
                </Nav.Link>
                 
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
};
export default HeaderNew;
