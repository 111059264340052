import React, { useEffect, useState } from "react";
import "./mainOpportunities.css";
import Select from "react-select";
import Aos from "aos";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import bannerimg from "../assets/images/HR/Oppurtunity and opening/banner.jpg";
import { useNavigate } from "react-router-dom";
import Socialicons from "../socialIcons";
import axios from "axios";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import SecondBannerCompo from "../SecondBannerCompo";

export default function MainOpportunities() {
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [showperPage, setshowperPage] = useState(5);
  const [disablebtn, setdisablebtn] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [selected, setSelected] = useState([]);
  const [rowDes, setRowDes] = useState();
  const [selectedone, setSelectedone] = useState([]);
  const [selectedtwo, setSelectedtwo] = useState([]);

  const indexofLast = currentPage * showperPage;
  const indexofFirst = indexofLast - showperPage;
  const currentlyPages = data.slice(indexofFirst, indexofLast);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);
  useEffect(() => {
    getAllOpportunities();
    // getAA();
  }, []);

  const modalData = (row) => {
    // setModalShow(true);
    setRowDes(row);
  };

  // const getAA = () => {
  //   axios.get("https://phpstack-370775-2346546.cloudwaysapps.com/public/jobOpportunities")
  //   .then((r) => {
  //     console.log(r)
  //   })
  //   .catch((e) => {console.log(e)})

  // }

  //on click table row modal data passing
  console.log(rowDes, "modal data ==========");

  const getAllOpportunities = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}public/jobOpportunities`)
      .then(function (response) {
        setdata(response?.data?.JobOpportunities);
        console.log(response?.data?.JobOpportunities, "JobOpportunities");
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const nextpage = () => {
    if (data?.length >= showperPage) {
      setshowperPage(3 + showperPage);
    } else {
      setdisablebtn(true);
    }
  };
  const options = [{ value: "chocolate", label: "Option 1" }];

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {rowDes?.Title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div dangerouslySetInnerHTML={{ __html: rowDes?.Description }} />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <section className="opportunities-page">
        <section className="">
          <div className="banner-div">
            <div className="empty-section-res"></div>
            <Socialicons
              value={"white"}
              customClass={"opportunities-banner-social-icons"}
            />
            <div className="img-div-whatweoffer">
              <img
                src={bannerimg}
                className="opportunities_banner bannerimg-whatwe-offer"
                alt="bannerimg"
                loading="lazy"
              />
            </div>
          </div>
        </section>
        <section className="whoweare opportunities-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <SecondBannerCompo
                  mainText={
                    <>
                      Diversifying available leads with talented professionals{" "}
                      <br></br>and ground-breaking opportunities for the masses
                    </>
                  }
                  classNameBorder="  responsibility_Green_Border"
                  classNameMainDiv="  responsibility_Main_Div"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="opportunities-contant">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10 col-md-12" data-aos="fade-up">
                <div className="opportunities-multiselect">
                  <h2 className="Montserrat-B greenColor">
                    SEARCH FOR OPENINGS
                  </h2>
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <input
                            type="text"
                            class="produciton-from form-control"
                            placeholder="Enter Job Title"
                          />
                        </div>
                        <div class="col-lg-2 col-md-6">
                          <select
                            class="produciton-from form-control"
                            placeholder="Department"
                          >
                            <option selected disabled value="volvo">
                              Department
                            </option>
                            <option value="it">IT</option>
                            <option value="hr">HR</option>
                            <option value="audi">Accounts</option>
                          </select>
                          {/* <Select
                          className="produciton"
                          value={selected}
                          onChange={setSelected}
                          options={options}
                          placeholder="Department"
                        /> */}
                        </div>
                        <div class="col-lg-2 col-md-6">
                          <select
                            class="produciton-from form-control"
                            placeholder="Location"
                          >
                            <option selected disabled value="volvo">
                              Location
                            </option>
                            <option>Karachi</option>
                          </select>
                        </div>
                        <div class="col-lg-2 col-md-6">
                          <select
                            class="produciton-from form-control"
                            placeholder="Contract"
                          >
                            <option selected disabled value="volvo">
                              Contract
                            </option>
                            <option value="fullTime">Full Time</option>
                          </select>
                          {/* <Select
                          className="Contract"
                          value={selected}
                          onChange={setSelected}
                          options={options}
                          placeholder="Contract"
                        /> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
        </section>
        <section className="Job-opening-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" data-aos="fade-up">
                <div>
                  <h2 className="Montserrat-B greenColor">Job Openings</h2>
                  <div className="overflowcheck">
                    <table className="tables-opportun">
                      <tr>
                        <th>Job Title</th>
                        <th></th>
                        <th className="text-center">Department</th>
                        <th className="text-center">Contract</th>
                        <th className="text-center">Location</th>
                        <th className="text-center">Position</th>
                        <th className="text-center">Due Date</th>
                      </tr>
                      {currentlyPages?.map((row) => (
                        <tr onClick={() => modalData(row)}>
                          <td>{row.Title}</td>
                          <td></td>
                          <td className="text-center">{row.Department}</td>
                          <td className="text-center">{row.Contract}</td>
                          <td className="text-center">{row.Location}</td>
                          <td className="text-center">{row.Position}</td>
                          <td className="text-center">
                            {moment(row.DueDate).format("MM-DD-YYYY")}
                          </td>
                        </tr>
                      ))}
                    </table>
                    <div className="foundation-minus globlbtnsdiv">
                      {disablebtn ? (
                        ""
                      ) : (
                        <button
                          type="button"
                          onClick={() => nextpage()}
                          className="Poppins-R custmbtn publicationbtn btn btn-light"
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="step-by">
          <div className="container">
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10" data-aos="fade-up">
                <div className="text-cnetered foundation-content">
                  <h2 className="Montserrat-B greenColor">
                    Your Application Process
                  </h2>
                </div>
                <div className="before-greenline">
                  <div className="greenline-service-div"></div>
                </div>
                <div className="text-cnetered mt-4">
                  <p className="Poppins-R grayColor">
                    Hamdard offers an Un-biased job application portal for all
                    candidates. We evaluate all applications based on the
                    applied position and the location of the available vacancy.
                    Our evaluation criteria is fair and transparent. Hamdard
                    practices a vigilant screening process which is based on the
                    application submitted by the candidate. Thus all candidates
                    are requested to carefully enter necessary data and apply
                    for the chosen job after going through the instructions. Our
                    job application process is broken down into the following 4
                    steps:
                  </p>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="stepps">
                <div>
                  <div class="row">
                    <div class="col-md-12" data-aos="fade-up">
                      <form id="msform">
                        <ul id="progressbar">
                          <li className="listyling">
                            Application
                            <br />
                            review and screening
                          </li>
                          <li className="listyling">
                            Interview
                            <br />
                            and Assessment
                          </li>
                          <li className="listyling">
                            Job
                            <br />
                            offer
                          </li>
                          <li className="listyling">
                            Onboarding
                            <br />
                            Orientation
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="learnmore-banner">
          <div className="container">
            <div className="row" data-aos="fade-up">
              <div className="">
                <h2 className="Montserrat-B greenColor">Learn More About Us</h2>
              </div>
              <div className="col-lg-4 col-md-12 mt-3">
                <div
                  onClick={() => navigate("/opportunities")}
                  className="bglearn"
                >
                  <div className="intercs">
                    <h2 className="Montserrat-B lightgray">
                      Opportunities
                      <br />& Openings
                    </h2>
                    <div className="hover-to-show">
                      <p className="Montserrat whiteColor">
                        Diversifying available leads with talented professionals
                        and ground-breaking opportunities for the masses{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 mt-3">
                <div
                  // onClick={() => setModalShow(true)}
                  className="bglearntow"
                >
                  <div className="intercs">
                    <h2 className="Montserrat-B lightgray">
                      Learning
                      <br />& Development
                    </h2>
                    <div className="hover-to-show">
                      <p className="Montserrat whiteColor ">
                        Chance to grow and learn with proficient experts at
                        Hamdard to expand your career horizons{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mt-3">
                <div
                  // onClick={() => setModalShow(true)}
                  className="bglearnthree"
                >
                  <div className="intercs">
                    <h2 className="Montserrat-B lightgray">
                      Gender
                      <br />
                      Equality
                    </h2>
                    <div className="hover-to-show">
                      <p className="Montserrat whiteColor">
                        Hamdard is committed to providing an inclusive work
                        environment that empowers all our employees without
                        exception. All organizational policies are designed to
                        maintain gender equality across all functions. We are
                        committed to providing an inclusive work environment
                        where all our employees can contribute to their own and
                        to Hamdard’s success. We value the diversity of our
                        teams and understand that, above all else, our people
                        are our greatest asset.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mt-5"></div>
      </section>
    </>
  );
}
