import React, { useEffect } from "react";
import Slider from "../Slider/slider";
import { Container } from "reactstrap";
import "./wellbeing.css";
import section1 from "../assets/images/Wellbeing/section-1-box image.jpg";
import section2 from "../assets/images/Wellbeing/section-2-boximage.jpg";
import section4 from "../assets/images/Wellbeing/section-4-boximage.jpg";
import section5 from "../assets/images/aboutpagebannerimage.svg";
import section6 from "../assets/images/Asset4.png";

// import before from '../assets/images/Assett1.svg';
// import after from '../assets/images/Assett7.svg';
import green from "../assets/images/line.png";
import hamdard from "../assets/images/Assettt.png";
import Man from "../assets/images/Assettt.png";

import Bannerfour from "../Home/bannerfour";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import bannerimg from "../assets/images/Wellbeing/bannerF2.jpg";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import before from "../assets/images/Assett1.svg";
import after from "../assets/images/Assett7.svg";
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import Socialicons from "../socialIcons";
import SecondBannerCompo from "../SecondBannerCompo";

export default function Wellbeing() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <>
      <section className="page-wellbeing">
        <section>
          <div className="banner-div">
            <div className="empty-section-res"></div>
            <div className="wellBeing_social_wrapper">
              <Socialicons value={"white"} />
            </div>
            <div className="img-div-whatweoffer">
              <img
                src={bannerimg}
                className="bannerimg-whatwe-offer"
                alt="bannerimg"
                // loading="lazy"
              />
            </div>
          </div>
        </section>

        <section className="banner-wellbeing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <SecondBannerCompo
                  mainText={
                    <>
                      “The possibilities are limitless in the future but we need
                      to hold hands with the rest of the world to sustain our
                      faith and work together for human welfare, and universal
                      peace.”
                    </>
                  }
                  classNameBorder="  wellBeing_Green_Border"
                  classNameMainDiv="  wellBeing_Main_Div"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-abouthamdard banner-one well-being-sec-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6" data-aos="flip-left">
                <div className="about-left-section-ib">
                  <div className="contant">
                    <h2 className="Montserrat-B greenColor">
                      Who we are & how we work
                    </h2>
                  </div>
                  <div className="contant-ptag-ib">
                    <p className="Poppins-R grayColor">
                      Research and Development arm of Hamdard Laboratories
                      (waqf) Pakistan is a team of qualified Professionals,
                      Hakims, Pharmacists and allied medical experts, that
                      strives for continuous improvements in Hamdard’s products.
                      <br></br>
                      <br></br>
                      {/* <ul className="list-group-numbered">
                      <li className="mb-3">Maintain health in all age groups<br></br></li>
                      <li className="mb-3">Overcome disease conditions. <br></br>
                      </li>
                    </ul> */}
                      <br></br>
                      The work is done in close collaboration with the relevant
                      Academia, and other research organizations.
                      <br></br>
                      <br></br>
                      The Research and Development work in Hamdard can be
                      categorized in following areas: <br></br>
                      <br></br>
                      <ul className="list-group-numbered">
                        <li className="mb-3">
                          Develop scientifically updated research & development
                          products and processes.
                        </li>
                        <li className="mb-3">
                          Establish Evidence-based awareness in Unani products.
                          {/* <ul className="list-group-numbered">
                    <li className="mb-3"> 	Proven Efficacy in best dosage form</li>
                    <li className="mb-3"> Safety  </li>
                    <li className="mb-3">   	Comparable with contemporary medicines</li>
                    <li className="mb-3">  Regulatory compliant  </li>
                    
                    </ul> */}
                        </li>
                        <li className="mb-3">
                          Review and, if required, modify
                          formulations/processes/dosage forms of existing
                          products.
                        </li>
                        <li className="mb-3">
                          Develop agricultural techniques to increase the
                          cultivation of medicinal herbs for better and
                          cost-effective ingredient availability.
                          {/* <ul className="list-group-numbered">
                    <li className="mb-3">Optimize product efficacy</li>
                    <li className="mb-3">	Easy administration </li>
                    <li className="mb-3">Improve quality </li>
                    <li className="mb-3">Reduce cost</li>
                    <li className="mb-3">Increase customer satisfaction</li>

                    </ul> */}
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-6" data-aos="flip-right">
                <div className="about-img-bg">
                  <img className="imgib" src={section1} alt="image" />
                  <div className="about-img-testing"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-img-bg">
            <img className="img1" src={Man} alt="image" data-aos="flip-right" />
          </div>
        </section>

        <section className="bg-abouthamdard banner-one">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6" data-aos="flip-right">
                <div className="about-img-bg">
                  <img className="imgib" src={section2} alt="image" />
                  <div className="about-img-testing"></div>
                </div>
              </div>

              <div className="col-md-12 col-lg-6" data-aos="flip-right">
                <div className="about-left-section">
                  <div className="contant">
                    <h2 className="Montserrat-B greenColor">
                      Hamdard Pakistan launches a unique public awareness
                      movement Hamdard Zindagi. This movement has been started
                      to highlight the importance of health in the country.
                    </h2>
                  </div>

                  <div className="contant-ptag">
                    <p className="Poppins-R grayColor">
                      There is no doubt that Unani medicines are popular not
                      only in Pakistan but also in the West. Medical experts
                      globally appreciate the merits of Unani medicine.
                      Therefore, Hamdard organizes a digital platform “Hamdard
                      Zindagi” to promote science-based education for a healthy
                      life.
                      <br></br>
                      <br></br>
                      Significant results of this initiative have begun to
                      emerge. Patients not only from Pakistan but also from
                      abroad are participating, Short Video based information is
                      also being shared online in which people get valuable
                      advise on health and nutrition.
                      <br></br>
                      <br></br>
                      Hamdard Zindagi is a multi-faceted initiative in which
                      people are advised to improve their health and get free
                      medical check-ups through online activities as well as
                      flyer campaigns. Hakeem Mohammed Said dedicated his life
                      to the development of the health sector in the country and
                      personally helped more than five million patients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        
        <Bannerfour />

        <div className="">
          {/* <section className="bgimg-greenbanner banner-four mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="container">
                  <div className="row">
                    <div className="bannerfour-contant-heading">
                      <div
                        className="bannerfoure-before-div"
                        data-aos="flip-up"
                      >
                        <img
                          className="bannerfoure-before"
                          src={before}
                          alt="before"
                        />
                      </div>
                      <div className="bannerfour-inner-contant">
                        <div className="contant4">
                          <h2
                            className="Montserrat-B"
                            style={{ color: "#fff" }}
                          >
                            We have each come into this world<br></br>
                            for a specific reason to live, love and serve
                            <br></br>
                            Allah through services of His creatures.
                          </h2>
                        </div>

                        <div className="contant-ptag2">
                          <p className="Montserrat-B" style={{ color: "#fff" }}>
                            SHAHEED HAKIM MOHAMMED SAID
                          </p>
                        </div>
                      </div>
                      <div
                        className="bannerfoure-after-div"
                        data-aos="flip-down"
                      >
                        <img
                          className="bannerfoure-after"
                          src={after}
                          alt="before"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>  */}
        </div>

        <section className="banner-1920-ib">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12" data-aos="fade-up">
                <div className="banner1920-heading-contant">
                  <h3 className="Montserrat-B">
                    HAMDARD LABORATORIES (WAQF) PAKISTAN
                  </h3>
                  <div className="contant-1920">
                    <h2 className="Montserrat-B" style={{ color: "#003634" }}>
                      RESEARCH & DEVELOPMENT DEPARTMENT<br></br>
                    </h2>
                  </div>
                  <h3>
                    {" "}
                    <strong>VISION</strong>
                  </h3>
                  <div className="contant-ptag">
                    <p
                      className="Poppins-R fs-5 text-lg-end"
                      style={{ color: "#003634" }}
                    >
                      بدلتے ہوےٗ صححی تقاضوں کے مطابق مؤثر، محفوظ، سہل
                      الاستعمال اور مناسب قیمت یونانی نسخہ جات پر تحقیق اور ان
                      کی تیاری کے ذریعہ تمام معاشرتی طبقات اور ہر عمر کے انسانوں
                      کے معیارحیات کوبہتر کرتے ہوۓ خوشگوار زندگی گزارنے کا موقع
                      فراہم کرنے کی سعی کرنا۔
                    </p>
                    <p className="Poppins-R" style={{ color: "#003634" }}>
                      <br></br>
                      <br></br>
                      Develop effective, efficient, safe and easy to use Unani
                      formulations at the optimal cost for masses to help
                      improve their life in all age brackets and bring happiness
                      to Humanity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12" data-aos="fade-up">
                <img src={section4} className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
