import React, { useEffect } from "react";
import mantab from "../assets/images/HR/Life at hamdard/02-be-you-feel-good.jpg";

import multiman from "../assets/images/HR/Life at hamdard/03-Life at hamdard.jpg";
import events_img from "../assets/images/HR/Life at hamdard/07-events.jpg";
import training_img from "../assets/images/HR/Life at hamdard/08-Training and workshop.jpg";
import health_img from "../assets/images/HR/Life at hamdard/09-Health camp.jpg";
import appreciation_img from "../assets/images/HR/Life at hamdard/10-Appreciation.jpg";

import Aos from "aos";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Fb } from "../assets/images/fb.svg";
import { ReactComponent as Insta } from "../assets/images/insta.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";
import { ReactComponent as Youtube } from "../assets/images/youtube.svg";
import bannerimg from "../assets/images/HR/Life at hamdard/01-Banner.jpg";
import Socialicons from '../socialIcons';
import { Button, Modal } from "react-bootstrap";

export default function BeYouFeel() {
  const [modalShow, setModalShow] = React.useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2500 });
  }, []);

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <h4>Lorem ipsum </h4>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
            ullamcorper suscipit lobortis nisl ut aliquip ex ea co consequat.
          </p>
        </Modal.Body>
        <Modal.Footer hidden>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <section>
        <div className="banner-div">
          <div className="empty-section-res"></div>
          <Socialicons value={"white"}/>
          <div className="img-div-whatweoffer">
            <img
              src={bannerimg}
              className="bannerimg-whatwe-offer"
              alt="bannerimg"
              loading="lazy"
            />
          </div>
        </div>
      </section>
      <section className="workwithus-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>

            <div className="col-lg-8 col-md-12" data-aos="fade-up">
              <div>
                <div className="">
                  <h5 className="Montserrat-M grayColor">
                  Work with Us -  <b>Reaching Exponential Heights </b>
                  </h5>
                </div>
                <div hidden className="">
                  <h2 className="Montserrat-B greenColor">
                    Be you
                    <br />
                    Feel good
                    <br />
                    Keep growing.
                  </h2>
                </div>
                <div className="greenline-work"></div>
                <div className="contant-ptag">
                  <p className="Poppins-R grayColor">
                    
                  Our Team at Hamdard is the inculcation of hard work and strategizing innovative techniques that have raised our standards. Working with the Hamdard company is a learning experience where employees understand the meaning of compassion and professionalism.  
<br></br>
<br></br>
Hamdard is keen on providing growth opportunities to its staff by giving chances to each and every employee that is working under the brand irrespective of any irregularities or differences. We take our employment programs seriously in terms of shortlisting candidates and optimizing our screening process. 

                  </p>
                  
                </div>
                <div>
                  <img src={mantab} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>

            <div className="col-lg-7 col-md-12 mt-5" data-aos="fade-up">
              <img src={multiman} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-5 col-md-12 mt-5" data-aos="fade-up">
              <div className="multiman-contant">
                <div className="">
                  <h2 className="Montserrat-B greenColor">Life at Hamdard</h2>
                </div>
                <div className="contant-ptag">
                  <p className="Poppins-R grayColor">
                  Our incentive programs are what truly separates us from other company’s work environments. We strongly believe in creating a work-life balance and understand that our employees must be motivated to run an extra mile and provide us with valuable results. To feel energized, Hamdard has inaugurated employee benefits and other incentive-based programs that are periodically revised. These create a source of healthy competition within our employees and make them feel appreciated and valued at our company. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="learnmore-banner">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="">
              <h2 className="Montserrat-B greenColor">Learn More About Us</h2>
            </div>
            <div className="col-lg-4 col-md-12 mt-3">
              <div
                onClick={() => navigate("/opportunities")}
                className="bglearn"
              >
                <div className="intercs">
                  <h2 className="Montserrat-B lightgray">
                    Opportunities
                    <br />& Openings
                  </h2>
                  <div className="hover-to-show">
                    <p className="Montserrat whiteColor">Diversifying available leads with talented professionals and ground-breaking opportunities for the masses  </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 mt-3">
              <div
              //  onClick={() => setModalShow(true)} 
              className="bglearntow">
                <div className="intercs">
                  <h2 className="Montserrat-B lightgray">
                    Learning
                    <br />& Development
                  </h2>
                  <div className="hover-to-show">
                    <p className="Montserrat whiteColor ">Chance to grow and learn with proficient experts at Hamdard to expand your career horizons  </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mt-3">
              <div 
              // onClick={() => setModalShow(true)} 
              className="bglearnthree">
                <div className="intercs">
                  <h2 className="Montserrat-B lightgray">
                    Gender
                    <br />
                    Equality
                  </h2>
                  <div className="hover-to-show">
                    <p className="Montserrat whiteColor">Hamdard is committed to providing an inclusive work environment that empowers all our employees without exception. All organizational policies are designed to maintain gender equality across all functions.
We are committed to provide an inclusive work environment where all our employees can contribute to their own and Hamdard’s success. We value the diversity of our teams and understand that, above all else, our people are our greatest asset.

</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="workwithus-banner">
        <div className="container">
          <div className="row" data-aos="fade-up">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-md-12">
              <div className="">
                <h2 className="Montserrat-B greenColor">Our Culture</h2>
                <div className="contant-ptag">
                  <p className="Poppins-R grayColor">
                  Hamdard for its employees is a base-ground for creating a wave of change in terms of a regular work environment. As a company that tends to people’s necessities, we encourage recreational activities that allow departments and individuals to work for and against each other. Throughout the year we introduce multiple sporting, formal and informal events including trainings, seminars, workshops, and health camps for our team.  
                  <br></br>
                  <br></br>
                  Hamdard has been known to create a stabilizing career path for its workers and introduce opportunities and events that will bring them forward into the limelight where quality and dedicated work is always appreciated.  

                  </p>
                </div>
              </div>
            </div>
           
            <div className="col-lg-6 col-md-12" data-aos="fade-up">
              <div
                onClick={() => {
                  navigate("/publication&Event");
                }}
                className="hover-abc text-center"
              >
                <img src={events_img} alt="" className="batimgdivop" />
                <h2
                  className="Montserrat-B greenColor mt-2"
                  style={{ paddingLeft: "30px" }}
                >
                  Events
                </h2>
                <div className="hover-to-show-op">
                <p className="Montserrat whiteColor">Throughout the year, multiple engagement events are organized to maintain a healthy and relaxed work environment. Sporting events have always been helpful in improving better teamwork among Hamdard.
                </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12" data-aos="fade-up">
              <div className="hover-abc text-center">
                <img src={training_img} alt="" className="batimgdivop" />
                <h2
                  className="Montserrat-B greenColor mt-2"
                  style={{ paddingLeft: "30px" }}
                >
                  Training & Workshops
                </h2>
                <div className="hover-to-show-op">
                  <p className="Montserrat whiteColor">
                  To better train and develop our workforce we regularly conduct training and workshops so our team is better equipped and ready to take on their tasks. Hamdard is keen on the appreciation and recognition of the efforts of its employees. We encourage building excellence in our people through continuous learning while keeping up with emerging work trends and culture. 
                  <p>• SAP Training • Long Service Awards • “JAAGO-JAGAO” Hamdard’s in-house employee engagement session
                      In-house and out-sourced training 
</p>
                  </p>
                </div>
              </div>
            </div>
          

            <div className="col-lg-6 col-md-12 mt-3 offset-lg-3" data-aos="fade-up">
              <div className="hover-abc text-center">
                <img src={health_img} alt="" className="batimgdivop" />
                <h2
                  className="Montserrat-B greenColor mt-2"
                  style={{ paddingLeft: "30px" }}
                >
                  Health Camps
                </h2>
                <div className="hover-to-show-op">
                  <p className="Montserrat whiteColor">
                  To mentally, emotionally, and socially coordinate with our team, we ensure medical screenings and health checks to improve their stability. At Hamdard, we take health and well-being very seriously which is why we have created defining standards that separate us from others as an employer.
                  </p>
                </div>
              </div>
            </div>
           
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>

      {/* <section className="Beapart-banner bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10 col-md-12" data-aos="fade-up">
              <div className="text-center">
                <h2 className="Montserrat-B ">Be a part of Hamdard </h2>
                <div className="contant-ptag">
                  <p className="Poppins-R ">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis{" "}
                  </p>
                </div>
                <div className="mt-3">
                  <button type="button" className="custmbtn-work">
                    <b
                      onClick={() => {
                        navigate("/opportunities");
                      }}
                    >
                      APPLY NOW
                    </b>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section> */}
    </>
  );
}
