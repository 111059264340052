import React from "react";

export default function MountainBanner() {
  return (
    <section className="bg-mmountains">
      <div className="container">
        <div className="row">
          <div className="col-lg-5"></div>
          <div className="col-md-12 col-lg-7">
            <div className="mountaindiv">
              <h2 className="Montserrat-M">
                <b>
                We are a global healthcare company with the aim of improve the quality of human life by helping people do more, feel better, and live longer
                </b>
              </h2>
              <div className="greenline-abouthamn"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
