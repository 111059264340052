import React from 'react';
import './home.css';
import Man from '../assets/images/train.png';
import green from '../assets/images/line.png';
import hamdard from '../assets/images/Assettt.png'
import { Container } from 'reactstrap';
import Aos from 'aos';
import { useNavigate } from "react-router-dom";

export default function BannerAboutHamdard() {
    const navigate = useNavigate();
    return (
        <section className="bg-abouthamdard banner-one">
            <Container>
                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="container">
                            <div className="row">
                                <div className="about-left-section" data-aos="flip-left" data-aos-once="true">
                                    <div className="contant-heading">
                                        <h5 className="Montserrat-M grayColor">About Us –  <b>Story etched in History</b></h5>
                                    </div>
                                    <div className="contant">
                                        <h2 className="Montserrat-B greenColor">What started as a Matab in the subcontinent became a global brand that is now redefining standards in Pakistan</h2>
                                    </div>
                                    <div className="greenline"></div>
                                    <div className="contant-ptag">
                                        <p className="Poppins-R grayColor">Hamdard started its journey from a small shop in an area of old Delhi, in 1906. Founded by an accomplished physician, Hakim Hafiz Abdul Majeed (father of Shaheed Hakim Mohammed Said). Hamdard quickly gained prominence as a distinguished Eastern Medicine manufacturer. Then, in Karachi, after the creation of Pakistan, his younger son, who wa a visionary physician, Hakim Mohammed Said, migrated to the newly-formed country and founded Hamdard Pakistan in a two-room rented clinic of Tibb-e-Unani in 1948. Under his leadership, Hamdard soon became a success story with its respected and well-liked products, especially Rooh Afza syrup and effective herbal medicines.
                                            <br></br>
                                            <br></br>
                                            Hamdard Pakistan efficiently manages a comprehensive network of free Hamdard herbal clinics (matabs) across the country, including two state-of-the-art <strong>Digital Matabs</strong> in Karachi. Moreover, Hamdard offers first-of-its-kind free <strong>Mobile Dispensaries</strong> to provide health facilities at patients’ doorsteps and has set up various Eastern and Western System of hospitals across the country
                                        </p>
                                    </div>
                                    <div className="btn-div">
                                        <button type="button" onClick={() => navigate("about-hamdard")} className="Poppins-R custmbtn btn btn-light">READ MORE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-6" data-aos="flip-right" data-aos-once="true">
                        <div className="about-img-bg">
                            <img className="img1" src={Man} alt="image" />
                            <div className="about-img-testing">
                            </div>
                        </div>
                    </div>
                    <div className="bottom-heading-img">
                        <img className="img3" src={hamdard} alt="image" />
                    </div>
                </div>
            </Container>
        </section>
    )
}
