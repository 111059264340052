import React,{useEffect} from 'react';
import ResponsibilityBanner from './responsibilityBanner';
import SecendBanner from './secendBanner';
import SliderleftImg from './sliderleftImg';
import LeftimgRightheading from './leftimgRightheading';
import RightimgLeftheading from './rightimgLeftheading';
import SecleftimgRightheading from './secleftimgRightheading';
import SecrightimgLeftheading from './secrightimgLeftheading';
import FoundaionOurRes from './foundaionOurRes';
import './ourResponsibility.css'
import BannerFoundation from '../Home/bannerFoundation';


export default function OurResponsibility() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
    <div className='Wrapper_Ourespons'>
        <ResponsibilityBanner/>
        <SecendBanner/>
        <SliderleftImg/>
        <LeftimgRightheading/>
        <RightimgLeftheading/>
        <SecleftimgRightheading/>
        <SecrightimgLeftheading/>
        <div className="founttest">
        {/* <FoundaionOurRes/> */}
            <BannerFoundation />

        </div>
               
    </div>
    )
}
